export const googleAnalyticsSetUserId = ({ userId }) => {
  if (typeof window.localStorage === 'undefined') {
    return;
  }

  if (userId) {
    window.localStorage.setItem('flex-user-id', userId);
    //todo: then the userId will be set here src/analytics/handlers.js
  } else {
    window.localStorage.removeItem('flex-user-id');
  }
};
