import axios from 'axios';
import config from '../config';

export const sendContactUsEmail = ({ name, email, message, topicType, currentUser }) => {
  const isUser = currentUser ? true : false;

  const sendContactUsEmail = axios.post(`${config.serverURL}/events/sendContactSubmission`, {
    name,
    email,
    message,
    topicType,
    isUser,
  });

  return Promise.resolve(sendContactUsEmail);
};

export const sendConfirmationOfProfileSubmission = ({ name, email }) => {
  const sendConfirmationOfProfileSubmission = axios.post(
    `${config.serverURL}/events/sendConfirmationOfProfileSubmission`,
    {
      name,
      email,
    }
  );

  return Promise.resolve(sendConfirmationOfProfileSubmission);
};

export const sendConfirmationOfNewProfileSubmissionToT4Y = ({ id, name }) => {
  const sendConfirmationOfNewProfileSubmissionToT4Y = axios.post(
    `${config.serverURL}/events/sendConfirmationOfNewProfileSubmissionToT4Y`,
    { id, name }
  );

  return Promise.resolve(sendConfirmationOfNewProfileSubmissionToT4Y);
};

export const sendRequestForChange = listingId => {
  const sendRequestForChange = axios.post(`${config.serverURL}/events/sendRequestForChange`, {
    listingId,
  });
  return Promise.resolve(sendRequestForChange);
};
