import React from 'react';
import css from './Tabs.css';
import classNames from 'classnames';
import 'react-tabs/style/react-tabs.css';

const TabList = ({ fields, onClickTab, onAddClass, selectedIndex }) => {
  return (
    <div className={css.tabList}>
      {fields.map((name, index) => (
        <div
          key={`TabList_${name}_${index}`}
          className={classNames(css.tab, { [css.selectedTab]: selectedIndex === index })}
          onClick={() => onClickTab(index)}>
          Class #{index + 1}
        </div>
      ))}
      <div className={css.tab} onClick={onAddClass}>
        Add new class
      </div>
    </div>
  );
};

export default TabList;
