import { ensureTransaction } from './data';

export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_REQUEST_PAYMENT_INSTANT = 'transition/request-payment-instant';
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_EXPIRE = 'transition/expire';
export const TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT = 'transition/customer-cancel-after-accept';
export const TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT = 'transition/provider-cancel-after-accept';
export const TRANSITION_DISABLE_CUSTOMER_CANCEL = 'transition/disable-customer-cancel';
export const TRANSITION_PROVIDER_CANCEL = 'transition/provider-cancel';
export const TRANSITION_DISABLE_PROVIDER_CANCEL = 'transition/disable-provider-cancel';
export const TRANSITION_FIFTEEN_MINUTES_BEFORE_PRIVATE_START =
  'transition/fifteen-minutes-before-private-start';
export const TRANSITION_CONFIRM_PAYMENT_INSTANT = 'transition/confirm-payment-instant';
export const TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT =
  'transition/provider-cancel-after-accept-instant';
export const TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT =
  'transition/customer-cancel-after-accept-instant';
export const TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS =
  'transition/disable-provider-cancel-public-class';
export const TRANSITION_CUSTOMER_CANCEL = 'transition/customer-cancel';
export const TRANSITION_FIFTEEN_MINUTES_BEFORE_PUBLIC_START =
  'transition/fifteen-minutes-before-public-start';
export const TRANSITION_CLASS_START = 'transition/class-start';
export const TRANSITION_CLASS_END = 'transition/class-end';
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_CONFIRM_PAYMENT_EVENT = 'transition/confirm-payment-event';
export const TRANSITION_CUSTOMER_CANCEL_EVENT = 'transition/customer-cancel-event';
export const TRANSITION_PROVIDER_CANCEL_EVENT = 'transition/provider-cancel-event';
export const TRANSITION_DISABLE_EVENT_CANCEL = 'transition/disable-event-cancel';
export const TRANSITION_FIFTEEN_MINUTES_BEFORE_EVENT_START =
  'transition/fifteen-minutes-before-event-start';

export const STATE_INITIAL = 'initial';
export const STATE_ENQUIRY = 'enquiry';
export const STATE_PENDING_PAYMENT = 'pending-payment';
export const STATE_PAYMENT_EXPIRED = 'payment-expired';
export const STATE_PREAUTHORIZED = 'preauthorized';
export const STATE_ACCEPTED = 'accepted';
export const STATE_DECLINED = 'declined';
export const STATE_CANCELLED = 'cancelled';
export const STATE_TWENTY_FOUR_HOURS_BEFORE_BOOKING_START =
  'twenty-four-hours-before-booking-start';
export const STATE_THIRTY_MINUTES_BEFORE_PRIVATE_CLASS_START =
  'thirty-minutes-before-private-class-start';
export const STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START = 'fifteen-minutes-before-class-start';
export const STATE_ACCEPTED_INSTANT = 'accepted-instant';
export const STATE_THIRTY_MINUTES_BEFORE_PUBLIC_START = 'thirty-minutes-before-public-start';
export const STATE_CLASS_STARTED = 'class-started';
export const STATE_DELIVERED = 'delivered';
export const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
export const STATE_REVIEWED = 'reviewed';
export const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
export const STATE_ACCEPTED_EVENT = 'accepted-event';
export const STATE_TWENTY_FOUR_HOURS_BEFORE_EVENT_START =
  'state-twenty-four-hours-before-event-start';

export const stateDescription = {
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_INSTANT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_REVIEWED]: {
      type: 'final',
    },
    [STATE_ENQUIRY]: {},
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_CONFIRM_PAYMENT_INSTANT]: STATE_ACCEPTED_INSTANT,
        [TRANSITION_CONFIRM_PAYMENT_EVENT]: STATE_ACCEPTED_EVENT,
      },
    },
    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_ACCEPTED_EVENT]: {
      on: {
        [TRANSITION_DISABLE_EVENT_CANCEL]: STATE_TWENTY_FOUR_HOURS_BEFORE_EVENT_START,
      },
    },
    [STATE_TWENTY_FOUR_HOURS_BEFORE_EVENT_START]: {
      on: {
        [TRANSITION_FIFTEEN_MINUTES_BEFORE_EVENT_START]: STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
      },
    },
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
      },
    },
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT]: STATE_CANCELLED,
        [TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT]: STATE_CANCELLED,
        [TRANSITION_DISABLE_CUSTOMER_CANCEL]: STATE_TWENTY_FOUR_HOURS_BEFORE_BOOKING_START,
      },
    },
    [STATE_DECLINED]: {},
    [STATE_CANCELLED]: {},
    [STATE_TWENTY_FOUR_HOURS_BEFORE_BOOKING_START]: {
      on: {
        [TRANSITION_PROVIDER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_DISABLE_PROVIDER_CANCEL]: STATE_THIRTY_MINUTES_BEFORE_PRIVATE_CLASS_START,
      },
    },
    [STATE_THIRTY_MINUTES_BEFORE_PRIVATE_CLASS_START]: {
      on: {
        [TRANSITION_FIFTEEN_MINUTES_BEFORE_PRIVATE_START]: STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
      },
    },
    [STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START]: {
      on: {
        [TRANSITION_CLASS_START]: STATE_CLASS_STARTED,
      },
    },
    [STATE_ACCEPTED_INSTANT]: {
      on: {
        [TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT]: STATE_CANCELLED,
        [TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT]: STATE_CANCELLED,
        [TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS]: STATE_THIRTY_MINUTES_BEFORE_PUBLIC_START,
      },
    },
    [STATE_THIRTY_MINUTES_BEFORE_PUBLIC_START]: {
      on: {
        [TRANSITION_CUSTOMER_CANCEL]: STATE_CANCELLED,
        [TRANSITION_FIFTEEN_MINUTES_BEFORE_PUBLIC_START]: STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
      },
    },
    [STATE_CLASS_STARTED]: {
      on: {
        [TRANSITION_CLASS_END]: STATE_DELIVERED,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
export const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
export const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
export const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  [
    ...getTransitionsToState(STATE_ACCEPTED),
    ...getTransitionsToState(STATE_ACCEPTED_INSTANT),
    ...getTransitionsToState(STATE_ACCEPTED_EVENT),
  ].includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx));

export const txIsFifteenMinBeforeClassStart = tx =>
  [
    ...getTransitionsToState(STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START),
    ...getTransitionsToState(STATE_THIRTY_MINUTES_BEFORE_PRIVATE_CLASS_START),
  ].includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ENQUIRE,
    TRANSITION_EXPIRE_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_ACCEPT,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT,
    TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_FIFTEEN_MINUTES_BEFORE_PRIVATE_START,
    TRANSITION_CONFIRM_PAYMENT_INSTANT,
    TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT,
    TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT,
    TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS,
    TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_FIFTEEN_MINUTES_BEFORE_PUBLIC_START,
    TRANSITION_CLASS_START,
    TRANSITION_CLASS_END,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [TRANSITION_REQUEST_PAYMENT, TRANSITION_REQUEST_PAYMENT_INSTANT].includes(transition);
};

export const isInstantBooking = tx => hasPassedTransition(TRANSITION_CONFIRM_PAYMENT_INSTANT, tx);

export const isEventBooking = tx => hasPassedTransition(TRANSITION_CONFIRM_PAYMENT_EVENT, tx);

export const txIsStart = tx => txLastTransition(tx) === TRANSITION_CLASS_START;
export const txIsEnd = tx => txLastTransition(tx) === TRANSITION_CLASS_END;

export const txIsProcess = tx =>
  [
    TRANSITION_DISABLE_CUSTOMER_CANCEL,
    TRANSITION_DISABLE_PROVIDER_CANCEL,
    TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS,
    TRANSITION_DISABLE_EVENT_CANCEL,
  ].includes(txLastTransition(tx));

export const STATES = statesFromStateDescription(stateDescription);
export const getStatesBetween = (startState, finishState, allStates, rootState) => {
  const stateNames = Object.keys(allStates);
  const result = [finishState];

  const stateReducer = stateNode => {
    if (stateNode === startState) {
      return true;
    }
    if (stateNode === rootState) return false;

    const statesPrev = stateNames.filter(state => {
      const stateObj = allStates[state];
      return stateObj.on && Object.values(stateObj.on).includes(stateNode);
    });

    const stateValid = statesPrev.filter(s => stateReducer(s));
    if (stateValid.length !== 0) {
      result.push(...stateValid);
      return true;
    } else {
      return false;
    }
  };
  stateReducer(finishState);

  return result;
};
