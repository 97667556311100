import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
  requiredAndNonEmptyString,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldRadioButtonGroup,
  FieldCheckboxGroup,
} from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';
import arrayMutator from 'final-form-arrays';

import css from './EditListingDescriptionForm.css';
import { findOptionsForSelectFilter } from 'util/search';
import config from 'config';
const LEVEL = findOptionsForSelectFilter('level', config.custom.filters);
const LANGUAGES = findOptionsForSelectFilter('languages', config.custom.filters);

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutator }}
    render={formRenderProps => {
      const {
        certificateOptions,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        isEventListing,
      } = formRenderProps;
      const listingType = isEventListing ? 'event' : 'listing';
      const titleMessage = intl.formatMessage({
        id: `EditListingDescriptionForm.${listingType}.title`,
      });
      const titlePlaceholderMessage = intl.formatMessage({
        id: `EditListingDescriptionForm.${listingType}.titlePlaceholder`,
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: `EditListingDescriptionForm.${listingType}.description`,
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const levelRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.levelRequiredMessage',
      });

      const levelLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.levelLabel',
      });

      const languageLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.languageLabel',
      });

      const languageRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.languageRequiredMessage',
      });

      const aboutAuthorLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.aboutAuthorLabel',
      });

      const aboutAuthorRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.aboutAuthorRequiredMessage',
      });

      const aboutAuthorPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.event.aboutAuthorPlaceholder',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <div className={css.field}>
            <FieldTextInput
              id="title"
              name="title"
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            />
          </div>

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          {isEventListing && (
            <>
              <div className={css.field}>
                <FieldRadioButtonGroup
                  twoColumns
                  name={'level'}
                  options={LEVEL}
                  id={'level'}
                  label={levelLabel}
                  validate={required(levelRequiredMessage)}
                />
              </div>
              <div className={css.field}>
                <FieldCheckboxGroup
                  name="languages"
                  label={languageLabel}
                  options={LANGUAGES}
                  twoColumns
                  validate={requiredFieldArrayCheckbox(languageRequiredMessage)}
                />
              </div>
              <div className={css.field}>
                <FieldTextInput
                  name="aboutAuthor"
                  label={aboutAuthorLabel}
                  type="textarea"
                  id="aboutAuthor"
                  placeholder={aboutAuthorPlaceholder}
                  validate={requiredAndNonEmptyString(aboutAuthorRequiredMessage)}
                />
              </div>
            </>
          )}
          {!isEventListing && (
            <CustomCertificateSelectFieldMaybe
              id="certificate"
              name="certificate"
              certificateOptions={certificateOptions}
              intl={intl}
            />
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}>
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
