import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import moment from 'moment';

import css from './TransactionPanel.css';

const JoinClassButtonMaybe = props => {
  const { className, rootClassName, showButtons, transaction } = props;

  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    const bookingStart =
      transaction &&
      transaction.booking &&
      transaction.booking.attributes &&
      transaction.booking.attributes.displayStart;

    const timeStart = moment(bookingStart);
    const now = moment();

    if (!(timeStart.diff(now, 'minute', true) > 15)) setActiveButton(true);
  }, [transaction]);

  const jitsyMeetLinkExisted =
    transaction &&
    transaction.attributes &&
    transaction.attributes.protectedData &&
    transaction.attributes.protectedData.jitsyMeetLink;

  const errorMessage = !jitsyMeetLinkExisted ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.unavailableTransaction" />
    </p>
  ) : jitsyMeetLinkExisted && jitsyMeetLinkExisted.search('https') !== -1 ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.wrongTransaction" />
    </p>
  ) : null;

  const classes = classNames(css.actionButtons, rootClassName);
  const buttonClasses = classNames(
    css.joinClassButtonRoot,
    !jitsyMeetLinkExisted && css.disableButton,
    className
  );

  if (!showButtons) return null;
  if (!activeButton) return null;

  return (
    <div className={classes}>
      {errorMessage && <div className={css.joinClassErrors}>{errorMessage}</div>}
      <div className={css.actionButtonWrapper}>
        {jitsyMeetLinkExisted && jitsyMeetLinkExisted.search('https') === -1 && (
          <NamedLink
            name="ClassJitsyPage"
            params={{ id: jitsyMeetLinkExisted }}
            className={buttonClasses}>
            <FormattedMessage id="TransactionPanel.joinClassButton" />
          </NamedLink>
        )}
      </div>
    </div>
  );
};

export default JoinClassButtonMaybe;
