import React, { memo } from 'react';
import PropTypes, { arrayOf, objectOf, oneOfType } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { Button, ListingLink } from '..';
import css from './EditListingClassesPanel.css';
import { EditListingClassForm } from '../../forms';
import isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import { closeClass } from 'containers/EditListingPage/EditListingPage.duck';
import { types } from 'util/sdkLoader';
import config from 'config';
const { Money } = types;

const YOGA_STYLES_FILTER_CONFIG = 'yogaStyles';

const EditListingClassesPanel = memo(props => {
  const {
    className,
    rootClassName,
    listing,
    onUpdateClass,
    classListings,
    onNextTab,
    updateClassInProgress,
    updateClassError,
    onDeleteClass,
    filtersConfig,
  } = props;
  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const handleCloseClass = (classId, isClosed) => dispatch(closeClass(classId, isClosed));

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingClassesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingClassesPanel.createListingTitle" />
  );

  const handleSaveClass = values => {
    // eslint-disable-next-line no-unused-vars
    const { state, location, availabilityPlan, id, prices, ...rest } = values;

    const { entries } = availabilityPlan || {};
    const availableDayOfWeek = entries ? entries.map(e => e.dayOfWeek) : [];
    const schedule = [...new Set(availableDayOfWeek)];

    const allYogaStyles = filtersConfig.find(f => f.id === YOGA_STYLES_FILTER_CONFIG);
    const chosenYogaType =
      allYogaStyles &&
      allYogaStyles.config &&
      allYogaStyles.config.options.find(y => y.key === rest.yogaType);
    const yogaTypeLabel = chosenYogaType.label;

    const params = {
      price: new Money(Math.min(...prices), config.currency),
      availabilityPlan,
      geolocation: location.selectedPlace.origin,
      publicData: {
        ...rest,
        address: location.selectedPlace.address,
        prices,
        schedule,
        yogaTypeLabel,
      },
    };
    if (id) {
      params.id = id;
    }
    onUpdateClass(params, isPublished);
  };

  const initialValues = {
    classes: classListings.map(l => {
      const { geolocation, availabilityPlan, publicData = {}, state } = l.attributes;
      const {
        isPrivate,
        yogaType,
        classType,
        languages,
        length,
        level,
        rules,
        address,
        prices,
      } = publicData;
      return {
        state,
        id: l.id,
        isPrivate,
        yogaType,
        classType,
        languages,
        length,
        level,
        rules,
        availabilityPlan,
        prices,
        location: {
          search: address,
          selectedPlace: {
            address,
            origin: geolocation,
          },
        },
      };
    }),
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <div>
        <EditListingClassForm
          onSaveClass={handleSaveClass}
          initialValues={initialValues}
          updateClassInProgress={updateClassInProgress}
          updateClassError={updateClassError}
          onDeleteClass={onDeleteClass}
          handleCloseClass={handleCloseClass}
        />
        {classListings.length > 0 && (
          <Button className={css.goToNextTabButton} onClick={onNextTab}>
            Next: Photos
          </Button>
        )}
      </div>
    </div>
  );
}, isEqual);

const { func, object, string, bool, array } = PropTypes;

EditListingClassesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  filtersConfig: config.custom.filters,
};

EditListingClassesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  classListings: arrayOf(propTypes.ownListing),
  onUpdateClass: func,
  onNextTab: func,
  updateClassError: oneOfType([string, bool, objectOf]),
  updateClassInProgress: bool,
  onDeleteClass: func,
  filtersConfig: array,
};

EditListingClassesPanel.displayName = 'EditListingClassesPanel';

export default EditListingClassesPanel;
