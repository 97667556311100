import moment from 'moment';

export const generateJitsyMeetLink = (listingId, bookingStart) => {
  const unixTimeStamp = moment(bookingStart).unix() * 1000;

  // Another way to generate
  // let code = 0;
  // for (let i = 0; i < listingId.length; i++) {
  //   if (typeof listingId[i] === "number") code += listingId[i];
  //   if (typeof listingId[i] === "string") code += listingId[i].charCodeAt(0);
  // }

  const jitsyMeetLink = `${listingId}-${unixTimeStamp}`;
  return jitsyMeetLink;
};
