import { createSelector } from 'reselect';
import { getMarketplaceEntitiesWithoutState } from 'ducks/marketplaceData.duck';
import { ensureCurrentUser, ensureOwnListing } from './data';

const getMarketplaceDataSelector = createSelector(
  state => state.marketplaceData,
  data => data
);

export const getOwnListingsSelector = ids =>
  createSelector(getMarketplaceDataSelector, marketplaceData =>
    getMarketplaceEntitiesWithoutState(
      marketplaceData,
      ids.map(id => ({ type: 'ownListing', id }))
    )
  );

export const currentUserSelector = createSelector(
  state => state.user.currentUser,
  user => ensureCurrentUser(user)
);

export const currentUserListingSelector = createSelector(
  state => state.user.currentUserListing,
  currentUserListing => ensureOwnListing(currentUserListing)
);
