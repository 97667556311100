import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './AboutPage.css';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Time For Yourself</h1>
          <img
            className={css.coverImage}
            src={"https://t4y-public-files.s3.eu-north-1.amazonaws.com/about-us/about-us.jpg"}
            alt="My first ice cream."
          />

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p>"Yoga is derived from the sanskrit root 'Yuj', meaning: 'to join' or 'to yoke' or 'to unite'"</p>
            </div>

            <div className={css.contentMain}>
              <p>
                Time for Yourself is an adventure that started with a dream.... what if we could also
                have access to yoga from our living room? we have access to pretty much everything else..
                why not yoga?
              </p>

              <h3 className={css.subtitle}>Today we can all get connected</h3>

              <p>
                This space is built for you: the soul who seeks the time to connect inside, who wants to find
                a way to connect and practice with his/her favourite teacher, while balancing work, family, a
                global pandemic and maybe your social, physical or mental status.
              </p>

              <p>
                Since 2020, the world has changed. In-studio Yoga is sometimes unavailable, but for some, it
                was also this way before. Now,  when you are travelling for business, on vacation, if being a
                new mom or dad has changed your day (and night) or if working from home has become your new
                normal, you can continue with your own practice, finding your internal space, meeting your
                teacher and connecting.
              </p>

              <p>
                Keeping your space and fitting Time for Yourself in your daily routine is a must for your
                physical, mental and spiritual well being, and NOW it is easier!
              </p>

              <h3 className={css.subtitle}> Take some Time for Yourself today </h3>

              <p>
                Because You should be your priority and because no matter how many responsibilities
                you have, You need to stay grounded, and we are here to help!
              </p>

              <div className={css.signature}>
                <p> with Love, </p>
                <p> The Time for Yourself team </p>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
