import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  getStatesBetween,
  getTransitionsToState,
  STATE_ACCEPTED,
  STATE_ACCEPTED_EVENT,
  STATE_ACCEPTED_INSTANT,
  STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
  STATE_INITIAL,
  STATES,
  TRANSITIONS,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { UPCOMING_TAB } from '../../util/types';
import uniq from 'lodash/uniq';
import { denormalisedResponseEntities } from '../../util/data';

const sortedTransactionsByBookingStart = txs =>
  sortBy(txs, tx => {
    return tx.booking && tx.booking.attributes ? tx.booking.attributes.start : null;
  });

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = payload.isUpcoming
        ? sortedTransactionsByBookingStart(payload.data)
        : sortedTransactions(payload.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.metadata,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = (response, isUpcoming) => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: {
    data: denormalisedResponseEntities(response),
    metadata: response.data.meta,
    isUpcoming,
  },
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 4;
const UPCOMING_TAB_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    'upcoming-classes': 'sale',
  };

  const isUpcomingTab = tab === UPCOMING_TAB;
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);
  const statesAfterAcceptedPrivate = getStatesBetween(
    STATE_ACCEPTED,
    STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
    STATES,
    STATE_INITIAL
  );
  const statesAfterAcceptedPublic = getStatesBetween(
    STATE_ACCEPTED_INSTANT,
    STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
    STATES,
    STATE_INITIAL
  );
  const statesAfterAcceptedEvent = getStatesBetween(
    STATE_ACCEPTED_EVENT,
    STATE_FIFTEEN_MINUTES_BEFORE_CLASS_START,
    STATES,
    STATE_INITIAL
  );
  const statesAcceptedToBookingStart = uniq([
    ...statesAfterAcceptedPrivate,
    ...statesAfterAcceptedPublic,
    ...statesAfterAcceptedEvent,
  ]);
  const transitionsAcceptedToBookingStart = statesAcceptedToBookingStart.reduce(
    (totalTransitions, state) => {
      const transitions = getTransitionsToState(state);
      return [...totalTransitions, ...transitions];
    },
    []
  );
  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: isUpcomingTab ? transitionsAcceptedToBookingStart : TRANSITIONS,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: isUpcomingTab ? UPCOMING_TAB_SIZE : INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response, isUpcomingTab));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
