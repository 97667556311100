import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT, LISTING_TYPE_EVENT, LISTING_TYPE_PROFILE } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    isEventListing,
  } = props;
  const type = isEventListing ? 'event' : 'listing';
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id={`EditListingDescriptionPanel.${type}.createListingTitle`} />
  );

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);

  const initialValues = {
    title,
    description,
  };
  if (!isEventListing) {
    initialValues.certificate = publicData.certificate;
  } else {
    initialValues.level = publicData.level;
    initialValues.languages = publicData.languages;
    initialValues.aboutAuthor = publicData.aboutAuthor;
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, certificate, ...rest } = values;
          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              listingType: isEventListing ? LISTING_TYPE_EVENT : LISTING_TYPE_PROFILE,
              isTitleUpdated: initialValues.title !== title,
            },
          };
          if (!isEventListing) {
            updateValues.publicData.certificate = certificate;
          } else {
            updateValues.publicData = {
              ...updateValues.publicData,
              ...rest,
            };
          }

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
        isEventListing={isEventListing}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  isEventListing: false,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  isEventListing: bool,
};

export default EditListingDescriptionPanel;
