import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  FieldSelect,
} from '../../components';
import config from '../../config';
import { Form, FormSpy } from 'react-final-form';

import css from './FAQForHost.css';

const data = {
  howToBecomeATeacherAtTimeForYourself: {
    topic: 'How to become a teacher at Time for Yourself',
    details:
      'The process is simple\n1) Sign up, provide us with the information we need about you, the yoga you teach and at least 1 picture where your face is recognizable and another- that will work as a banner.\n2) Set your schedule online: follow the very few steps to provide us with your schedule information. The system will guide you through it\n3) Provide us with your banking information so we can pay you. We need a bank account number\n4) Submit for review.\n5) In 24 hours or less you will receive a confirmation that your profile is all set and you will be able to start teaching. If we need more information from you we will contact you right away and we will start with step 5 again',
  },
  bookings: {
    topic: 'Bookings',
    details: `Bookings are done all through T4Y in advance or up to 1 min prior to the start of the class.`,
  },
  classes: {
    topic: 'Classes',
    details: `\nClass rules: There are no class rules set by T4Y: you can teach any yoga, in any language with any length , from anywhere and at any time. The rules to follow are the general rules for decent practice\nType of classes: With T4Y you can set up Hybrid classes(Online/studio), Online only &Private 121; digital and in person.\nPrices of the classes: You set your own price for the class. There are several options for you to choose from in the system.`,
  },
  equipment: {
    topic: 'Equipment',
    details:
      'You can teach yoga from your cellphone, ipad or computer easily. Please note that sound and light are important aspects to consider and that you may need a camera, or a microphone.. You will ALWAYS need a stable internet connection.',
  },
  contractAndTaxes: {
    topic: 'Contract and taxes',
    details:
      'We will not have a contract with you. We are not changing your employment status. When you sign up and give classes with us you agree to our terms and conditions and acknowledge that this information has been provided. We also can’t do taxes for you but T4Y will provide you with reports of all your income generated, so your tax filing will be a lot easier!',
  },
  advertisingAndMarketing: {
    topic: 'Advertising and Marketing',
    details:
      'We have an advertising and marketing campaign in place and we will be advertising the platform in several venues and also you! The teachers, in social media and the alike.\nBy working with us you allow us to use your profile picture and basic class information to advertise you in places such as IG and FB stories. \nYou are by no means obliged to advertise T4Y but you are highly encouraged to share the posts/stories and the alike with your communities.',
  },
  geographicReach: {
    topic: 'Geographic reach',
    details:
      'We are opening almost exclusively in Switzerland, but we also have teachers in some nearby countries in the Eurozone.',
  },
  Events: {
    topic: 'Events',
    details:
      'You can set up Events through T4Y. As of Dec 2020, T4Y will set up the events in T4Y for you, you will just need to provide the details and will be able to see a preview.\nEvent rules: There are no restrictions on length, topic, day of the week/month/year,  language, limit of participants etc.\nTypes of Events: you can set up digital only events or hybrid events\nEvents price: completely open. No predefined set of prices is set up.',
  },
  Studios: {
    topic: 'Studios',
    details:
      'If you are a studio you have several options depending on your set up. You can set an account as a studio and manage your own teachers, or you can have your teachers set an account individually and manage them and the revenue as you normally do.',
  },
  Membership: {
    topic: 'Membership',
    details:
      'Membership coverage: booking, virtual connection, advertising, trouble shooting, customer support, event management ( posting advertising etc), secure payment via STRIPE, reporting of revenue and monthly payments to you,  and video recording and management- if applicable.\nMembership cost: if you set up 3 or less classes per week in your profile, your membership cost is 25 CHF/25 E (billed annually: 250CHF/ 250E), and if you have more than 3 classes per week your monthly membership is 50CHF/50E per month ( billed annually 500 CHF/500E)',
  },
  revenueForTheTeachers: {
    topic: 'Revenue for the teachers',
    details:
      'CLASSES\n\tGroup classes ( hybrid or digital): All teachers will receive 60% net of what they generate.\n\t\t* T4Y takes care of all commissions, taxes and fees for the transactions. This applies to all teachers the same. \n\t\t* Note that there will be students that will choose to ‘ pay as you go’ with the prices you set up for your  class, and some other students will have unlimited membership. A pro-rata system is in place to distribute the amount of the membership amongst the teachers that have had unlimited membership students in their class.\n\t\t*Also, we have built the option for you to choose different prices for a given class if a student tier is needed- i.e. a bit of a cheaper price for students.\n\tPrivate classes: 60% rate if Online, and 80% if physical.\nEVENTS\n\tTeachers receive 75% of the revenue that they generate- if event is Online and 90% if event is hybrid',
  },
  cancellation: {
    topic: 'Cancellations',
    details:
      "We hope you don’t need to cancel any classes or events, but of course there are things that cannot be predicted! if you have to, please do so at the earliest.\nHow to cancel a class:\n\t• Sign in into your account, and go to your Classes\n\t• Select the class you need to cancel and select cancel\n\t• The system is set up so your students will receive an automatic email informing them about the cancellation.\n*Please Note that you should cancel your class at least 30 min before the start of the class and your events at least 24hours before the start of the event. The class will be automatically cancelled if the Teacher doesn't open the class after 15 min and full refund to students will be triggered\n\nHow to cancel an event:\n\t•Sign in into your account, and go to Your events\n\t• Select the event and select cancel\n\t• The system is set up so your students will receive an automatic email informing them about the cancellation.\n*Please Note that you should cancel your event at least 24 hours before the start of the event.\nThe EVENT will be automatically cancelled if the Teacher doesn't open the EVENT after 30 min and full refund to students will be triggered",
  },
};

const TermsOfServicePageComponent = props => {
  const { scrollingDisabled, intl } = props;

  const tabs = [
    {
      text: intl.formatMessage({ id: 'FAQPage.faqForGuest' }),
      selected: false,
      linkProps: {
        name: 'FAQForGuest',
      },
    },
    {
      text: intl.formatMessage({ id: 'FAQPage.faqForHost' }),
      selected: true,
      linkProps: {
        name: 'FAQForHost',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'FAQPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const topics = Object.entries(data).map(en => ({
    title: en[1].topic,
    key: en[0],
  }));

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQForHost" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id="FAQForHost.heading" />
            </h1>
            <Form
              onSubmit={() => {}}
              render={() => {
                return (
                  <div>
                    <FieldSelect name="topic">
                      <option disabled value="">
                        Select a topic
                      </option>
                      {topics.map(tp => (
                        <option key={tp.key} value={tp.key}>
                          {tp.title}
                        </option>
                      ))}
                    </FieldSelect>

                    <FormSpy
                      subscription={{ values: true }}
                      render={({ values }) => {
                        const { topic } = values;
                        const selectedTopic = data[topic];
                        if (selectedTopic) {
                          return (
                            <div>
                              <p style={{ lineBreak: 'normal', whiteSpace: 'pre-wrap' }}>
                                {selectedTopic.details}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const TermsOfServicePage = compose(
  connect(mapStateToProps),
  injectIntl
)(TermsOfServicePageComponent);

export default TermsOfServicePage;
