import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, PrimaryButton, IconCheckmark } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import TopicSelectField from './TopicSelectField';
import { topicContactUsConfig } from '../../marketplace-custom-config';
import css from './ContactUsForm.css';

class ContactUsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.submittedValues = {};

    this.state = {
      focusedInput: null,
    };
  }

  onFocusedInputChange = focusedInput => {
    this.setState({ focusedInput });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={e => {
          return this.props
            .onSubmit(e)
            .then(() => {})
            .catch(() => {});
        }}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            handleSubmit,
            submitInProgress,
            sendMessageSuccess,
            sendMessageError,
            invalid,
            formId,
            intl,
            values,
          } = formRenderProps;

          const nameLabel = intl.formatMessage({ id: 'ContactUsForm.nameLabel' });
          const namePlaceholder = intl.formatMessage({ id: 'ContactUsForm.namePlaceholder' });
          const nameRequired = validators.required(
            intl.formatMessage({
              id: 'ContactUsForm.nameRequireMessage',
            })
          );

          const emailLabel = intl.formatMessage({ id: 'ContactUsForm.emailLabel' });
          const emailPlaceholder = intl.formatMessage({ id: 'ContactUsForm.emailPlaceholder' });
          const emailRequired = validators.required(
            intl.formatMessage({
              id: 'ContactUsForm.emailRequireMessage',
            })
          );

          const contentLabel = intl.formatMessage({ id: 'ContactUsForm.contentLabel' });
          const contentPlaceholder = intl.formatMessage({ id: 'ContactUsForm.contentPlaceholder' });
          const contentRequired = validators.required(
            intl.formatMessage({
              id: 'ContactUsForm.contentRequireMessage',
            })
          );

          const classes = classNames(rootClassName || css.root, className);

          const submitDisabled =
            invalid ||
            submitInProgress ||
            !values.name ||
            !values.email ||
            !values.message ||
            !values.topic;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}>
              <h1> Contact details </h1>

              <FieldTextInput
                className={css.textfield}
                type="text"
                id={formId ? `${formId}.name` : 'name'}
                name="name"
                label={nameLabel}
                placeholder={namePlaceholder}
                validate={nameRequired}
              />

              <FieldTextInput
                className={css.textfield}
                type="text"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={emailRequired}
              />

              <TopicSelectField
                id="topic"
                name="topic"
                categories={topicContactUsConfig}
                intl={intl}
              />

              <FieldTextInput
                className={css.textfield}
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                label={contentLabel}
                placeholder={contentPlaceholder}
                validate={contentRequired}
              />

              <div className={css.submitContainer}>
                <FormSpy subscription={{ pristine: true }}>
                  {props => {
                    return props.pristine ? (
                      <div>
                        <div className={css.errorContainer}>
                          {sendMessageError ? (
                            <p className={css.error}>
                              <FormattedMessage id="ContactUsForm.sendFailed" />
                            </p>
                          ) : null}
                        </div>
                        {sendMessageSuccess ? (
                          <p className={css.sentSuccess}>
                            <IconCheckmark className={css.checkmark} />
                            &nbsp;&nbsp;&nbsp;
                            <FormattedMessage id="ContactUsForm.sendSuccess" />
                          </p>
                        ) : null}
                      </div>
                    ) : null;
                  }}
                </FormSpy>
                <PrimaryButton
                  rootClassName={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}>
                  <FormattedMessage id="ContactUsForm.sendMessage" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactUsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  sendMessageError: null,
};

ContactUsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactUsForm = compose(injectIntl)(ContactUsFormComponent);

ContactUsForm.displayName = 'ContactUsForm';

export default ContactUsForm;
