import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconNotification.css';

const IconNotification = props => {
  const { className } = props;
  const classes = classNames(css.root, className);
  return (
    <svg
      className={classes}
      width="50px"
      height="50px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M411 262.862V215c0-69.822-46.411-129.001-110-148.33V45c0-24.813-20.187-45-45-45s-45 20.187-45 45v21.67C147.41 85.999 101 145.177 101 215v47.862c0 61.332-23.378 119.488-65.827 163.756A14.999 14.999 0 0046 452h136.509c6.968 34.192 37.272 60 73.491 60 36.22 0 66.522-25.808 73.491-60H466a15 15 0 0010.827-25.382C434.378 382.35 411 324.193 411 262.862zM241 45c0-8.271 6.729-15 15-15s15 6.729 15 15v15.728c-4.937-.476-9.94-.728-15-.728s-10.063.252-15 .728zm15 437c-19.555 0-36.228-12.541-42.42-30h84.84c-6.192 17.459-22.865 30-42.42 30zM78.33 422C112.491 376.208 131 320.792 131 262.862V215c0-68.925 56.075-125 125-125s125 56.075 125 125v47.862c0 57.93 18.509 113.346 52.671 159.138zM451 215c0 8.284 6.716 15 15 15s15-6.716 15-15c0-60.1-23.404-116.603-65.901-159.1-5.857-5.857-15.355-5.858-21.213 0s-5.858 15.355 0 21.213C430.717 113.944 451 162.913 451 215zM46 230c8.284 0 15-6.716 15-15 0-52.086 20.284-101.055 57.114-137.886 5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0C54.404 98.398 31 154.9 31 215c0 8.284 6.716 15 15 15z" />
    </svg>
  );
};

IconNotification.defaultProps = { className: null };

const { string } = PropTypes;

IconNotification.propTypes = {
  className: string,
};

export default IconNotification;
