import {
  FieldRadioButtonGroup,
  FieldCheckboxGroup,
  FieldTextInput,
  LocationAutocompleteInputField,
  Button,
  FieldSelect,
  FieldCheckbox,
} from 'components';

import React from 'react';
import Availability from './Availability';
import config from '../../config';
import { findOptionsForSelectFilter } from 'util/search';
import css from './Tabs.css';
import {
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  requiredFieldArrayCheckbox,
} from 'util/validators';
import identity from 'lodash/identity';
import { useSelector } from 'react-redux';
import { LISTING_STATE_CLOSED } from 'util/types';
import { bool, func, object, oneOfType, shape, string } from 'prop-types';

const YOGA_STYLES = findOptionsForSelectFilter('yogaStyles', config.custom.filters);
const CLASS_TYPE = findOptionsForSelectFilter('classType', config.custom.filters);
const PRIVATE_CLASS_TYPE = CLASS_TYPE.filter(cl => cl.key === 'digital' || cl.key === 'physical');
const PUBLIC_CLASS_TYPE = CLASS_TYPE.filter(cl => cl.key === 'digital' || cl.key === 'hybrid');
const LANGUAGES = findOptionsForSelectFilter('languages', config.custom.filters);
const LEVEL = findOptionsForSelectFilter('level', config.custom.filters);
const LENGTHS = config.custom.lengths;
const PRIVATE_LENGTHS = LENGTHS.filter(l => l.key === '60' || l.key === '90');

const getPriceOptions = (classType, isPrivate) => {
  if (isPrivate) {
    return config.custom.listingPricing.private[classType] || [];
  } else {
    return config.custom.listingPricing.normal;
  }
};

const deleteClassInProgressSelector = state => state.EditListingPage.deleteClassInProgress;
const deleteClassErrorSelector = state => state.EditListingPage.deleteClassError;

const TabPanel = ({
  id = 'TabPanel',
  name,
  value = {},
  intl,
  form,
  onSaveClass,
  updateClassInProgress,
  updateClassError,
  invalid,
  onDeleteClass,
  onCloseClass,
}) => {
  const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
  const addressPlaceholderMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressPlaceholder',
  });
  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });
  const priceRequired = requiredFieldArrayCheckbox(
    intl.formatMessage({
      id: 'EditListingPricingForm.priceRequired',
    })
  );

  const deleteClassInProgress = useSelector(deleteClassInProgressSelector);
  const deleteClassError = useSelector(deleteClassErrorSelector);

  const priceValidators = priceRequired;
  const { isPrivate, length, availabilityPlan = null, id: classId, state, classType } = value;

  const isPrivateLabel = intl.formatMessage({ id: 'EditListingClassForm.isPrivateLabel' });
  const yogaTypeLabel = intl.formatMessage({ id: 'EditListingClassForm.yogaTypeLabel' });
  const classTypeLabel = intl.formatMessage({ id: 'EditListingClassForm.classTypeLabel' });
  const languageLabel = intl.formatMessage({ id: 'EditListingClassForm.languageLabel' });
  const lengthLabel = intl.formatMessage({ id: 'EditListingClassForm.lengthLabel' });
  const levelLabel = intl.formatMessage({ id: 'EditListingClassForm.levelLabel' });
  const priceLabel = intl.formatMessage({ id: 'EditListingClassForm.priceLabel' });
  const rulesLabel = intl.formatMessage({ id: 'EditListingClassForm.rulesLabel' });

  const yogaTypeRequiredMessage = intl.formatMessage({
    id: 'EditListingClassForm.yogaTypeRequiredMessage',
  });
  const classTypeRequiredMessage = intl.formatMessage({
    id: 'EditListingClassForm.classTypeRequiredMessage',
  });
  const languageRequiredMessage = intl.formatMessage({
    id: 'EditListingClassForm.languageRequiredMessage',
  });
  const lengthRequiredMessage = intl.formatMessage({
    id: 'EditListingClassForm.lengthRequiredMessage',
  });
  const levelRequiredMessage = intl.formatMessage({
    id: 'EditListingClassForm.levelRequiredMessage',
  });

  const priceOptions = getPriceOptions(classType, isPrivate);

  return (
    <div className={css.tabPanel}>
      <div className={css.field}>
        <FieldCheckbox
          name={`${name}.isPrivate`}
          label={isPrivateLabel}
          id={`${id}_${name}_isPrivate`}
        />
      </div>
      <div className={css.field}>
        <FieldRadioButtonGroup
          twoColumns
          name={`${name}.yogaType`}
          options={YOGA_STYLES}
          id={`${id}_${name}_yogaType`}
          label={yogaTypeLabel}
          validate={requiredFieldArrayCheckbox(yogaTypeRequiredMessage)}
        />
      </div>
      <div className={css.field}>
        <FieldSelect
          name={`${name}.classType`}
          id={`${id}_${name}_classType`}
          label={classTypeLabel}
          validate={required(classTypeRequiredMessage)}>
          <option disabled value="">
            Select class type
          </option>
          {(isPrivate ? PRIVATE_CLASS_TYPE : PUBLIC_CLASS_TYPE).map(ct => (
            <option value={ct.key} key={ct.key}>
              {intl.formatMessage({
                id: ct.labelId,
                defaultMessage: ct.label,
              })}
            </option>
          ))}
        </FieldSelect>
      </div>
      <div className={css.field}>
        <FieldCheckboxGroup
          twoColumns
          name={`${name}.languages`}
          id={`${id}${name}_languages`}
          label={languageLabel}
          options={LANGUAGES}
          validate={requiredFieldArrayCheckbox(languageRequiredMessage)}
        />
      </div>
      <div className={css.field}>
        <FieldRadioButtonGroup
          twoColumns
          name={`${name}.length`}
          options={isPrivate ? PRIVATE_LENGTHS : LENGTHS}
          id={`${id}_${name}_length`}
          label={lengthLabel}
          validate={required(lengthRequiredMessage)}
        />
      </div>
      <div className={css.field}>
        <FieldRadioButtonGroup
          twoColumns
          name={`${name}.level`}
          options={LEVEL}
          id={`${id}_${name}level`}
          label={levelLabel}
          validate={required(levelRequiredMessage)}
        />
      </div>
      {classType && (
        <div className={css.field}>
          <FieldCheckboxGroup
            name={`${name}.prices`}
            id={`${id}_prices`}
            label={priceLabel}
            options={priceOptions}
            validate={priceValidators}
          />
        </div>
      )}
      <div className={css.field}>
        <FieldTextInput
          name={`${name}.rules`}
          id={`${id}_${name}_rules`}
          label={rulesLabel}
          type="textarea"
        />
      </div>
      <div className={css.field} style={{ zIndex: 10 }}>
        <LocationAutocompleteInputField
          className={css.locationAddress}
          inputClassName={css.locationAutocompleteInput}
          iconClassName={css.locationAutocompleteInputIcon}
          predictionsClassName={css.predictionsRoot}
          validClassName={css.validLocation}
          id={`${id}_${name}_location`}
          name={`${name}.location`}
          label={titleRequiredMessage}
          placeholder={addressPlaceholderMessage}
          useDefaultPredictions={false}
          format={identity}
          valueFromForm={value.location}
          validate={composeValidators(
            autocompleteSearchRequired(addressRequiredMessage),
            autocompletePlaceSelected(addressNotRecognizedMessage)
          )}
        />
      </div>
      <div className={css.field} style={{ zIndex: 10 }}>
        <FieldTextInput
          type="text"
          name={`${name}.apartment`}
          label="Apartment #"
          id={`${id}_apartment`}
        />
      </div>
      <Availability
        availabilityPlan={availabilityPlan}
        length={length}
        onSubmit={values => form.change(`${name}.availabilityPlan`, values.availabilityPlan)}
      />

      {updateClassError && <div>Update class error</div>}
      {deleteClassError && <div>Delete class error</div>}

      <div className={css.buttons}>
        <Button
          className={css.actionButton}
          type="button"
          onClick={() => onSaveClass(value)}
          inProgress={updateClassInProgress}
          disabled={invalid}>
          Save
        </Button>
        {state && (
          <Button
            className={css.actionButton}
            type="button"
            onClick={() => onCloseClass(classId, state === LISTING_STATE_CLOSED)}>
            {state === LISTING_STATE_CLOSED ? 'Open' : 'Close'}
          </Button>
        )}
        <Button
          className={css.actionButton}
          type="button"
          onClick={onDeleteClass}
          inProgress={deleteClassInProgress}>
          Delete
        </Button>
      </div>
    </div>
  );
};

TabPanel.propTypes = {
  id: string,
  name: string,
  value: object,
  intl: shape({
    formatMessage: func,
  }),
  form: shape({
    change: func,
  }),
  onSaveClass: func,
  updateClassInProgress: bool,
  updateClassError: oneOfType([bool, string, object]),
  invalid: bool,
  onDeleteClass: func,
  onCloseClass: func,
};

export default TabPanel;
