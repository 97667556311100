import React, { useState } from 'react';
import { LISTING_STATE_CLOSED } from 'util/types';
import TabList from './TabList';
import TabPanel from './TabPanel';
import { arrayOf, bool, func, object, oneOfType, shape, string } from 'prop-types';

const Tabs = ({
  fields,
  intl,
  form,
  onSaveClass,
  updateClassInProgress,
  updateClassError,
  invalid,
  onDeleteClass,
  handleCloseClass: handleCloseClassFunc,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleDeleteClass = index => async () => {
    const currentClass = fields.value[index] || {};
    const { id } = currentClass;
    if (id) {
      try {
        await onDeleteClass(id);
      } catch (error) {
        console.error(error);
      }
    }
    fields.remove(index);
    setSelectedIndex(index - 1 < 0 ? 0 : index - 1);
  };

  const handleCloseClass = index => async (classId, isClosed) => {
    try {
      await handleCloseClassFunc(classId, isClosed);
      fields.update(index, {
        ...fields.value[index],
        state: isClosed ? null : LISTING_STATE_CLOSED,
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <TabList
        fields={fields}
        onClickTab={setSelectedIndex}
        onAddClass={() => {
          fields.push(undefined);
          setSelectedIndex(fields.length);
        }}
        selectedIndex={selectedIndex}
      />
      {fields.map((name, index) => {
        if (index === selectedIndex)
          return (
            <TabPanel
              key={`TabPanel_${name}_${index}`}
              intl={intl}
              name={name}
              value={fields.value[index]}
              form={form}
              onSaveClass={onSaveClass}
              updateClassInProgress={updateClassInProgress}
              updateClassError={updateClassError}
              invalid={invalid}
              onDeleteClass={handleDeleteClass(index)}
              onCloseClass={handleCloseClass(index)}
            />
          );
        return null;
      })}
    </div>
  );
};

Tabs.propTypes = {
  fields: shape({
    value: arrayOf(object),
    remove: func,
    push: func,
    map: func,
  }),
  onSaveClass: func,
  updateClassInProgress: bool,
  updateClassError: oneOfType([bool, string, object]),
  invalid: bool,
  onDeleteClass: func,
  form: object,
  handleCloseClass: func,
  intl: object,
};

export default Tabs;
