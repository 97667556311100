import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { NamedLink } from '../../components';

import css from './SectionLocations.css';

import { geocoderMapbox } from 'components/LocationAutocompleteInput/GeocoderMapbox';
import { createResourceLocatorString } from 'util/routes';
import { useHistory } from 'react-router-dom';
import routeConfiguration from 'routeConfiguration';
import { encodeLatLng, encodeLatLngBounds } from 'util/urlHelpers';
import config from '../../config';

class LocationImage extends PureComponent {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const LocationLink = ({ name, image, searchQuery, nameText }) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>{nameText}</div>
    </NamedLink>
  );
};
const CurrentLocationLink = ({ name, image, nameText }) => {
  const history = useHistory();
  const onClick = () => {
    return geocoderMapbox
      .getPlaceDetails({
        id: 'current-location',
      })
      .then(({ bounds, origin }) => {
        const url = createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          {
            origin: encodeLatLng(origin),
            bounds: encodeLatLngBounds(bounds),
          }
        );
        history.push(url);
      });
  };
  return (
    // eslint-disable-next-line
    <a className={css.location} onClick={onClick}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>{nameText}</div>
    </a>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;
  const intl = useIntl();

  const classes = classNames(rootClassName || css.root, className);

  const todayName = (
    <FormattedMessage
      id="SectionLocations.classes"
      values={{
        text: (
          <span className={css.highlight}>
            <FormattedMessage id="SectionLocations.today" />
          </span>
        ),
      }}
    />
  );

  const nearYouName = (
    <FormattedMessage
      id="SectionLocations.classes"
      values={{
        text: (
          <span className={css.highlight}>
            <FormattedMessage id="SectionLocations.nearYou" />
          </span>
        ),
      }}
    />
  );

  const activityName = (
    <span className={css.highlight}>
      <FormattedMessage id="SectionLocations.eventsAndCommunityActivities" />
    </span>
  );

  const today = moment(new Date()).format('YYYY-MM-DD');
  const lastOfWeek = moment(new Date()).endOf('week').format('YYYY-MM-DD');

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        <LocationLink
          name={intl.formatMessage({ id: 'SectionLocations.today' })}
          nameText={todayName}
          image={`${config.custom.imageDomain}/landing-page/yoga-class-1.jpg`}
          searchQuery={`?isClassToday=true&dates=${today}%2C${lastOfWeek}&pub_listingType=class`}
        />
        <CurrentLocationLink
          name={intl.formatMessage({ id: 'SectionLocations.nearYou' })}
          nameText={nearYouName}
          image={`${config.custom.imageDomain}/landing-page/yoga-class-2.jpg`}
          searchQuery={`?isClassToday=true&dates=${today}%2C${lastOfWeek}&pub_listingType=class`}
        />
        <LocationLink
          name={intl.formatMessage({ id: 'SectionLocations.eventsAndCommunityActivities' })}
          nameText={activityName}
          image={`${config.custom.imageDomain}/landing-page/yoga-class-3.jpg`}
          searchQuery="?pub_listingType=event"
        />
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
