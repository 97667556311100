import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import logo from '../../assets/logoOnlyName.png';

import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className, isHideButton } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.logoWrapper}>
        {/* eslint-disable-next-line */}
        <img src={logo} className={css.heroLogo} />
      </div>
      <div className={css.heroContent}>
        {/* <div className={css.heroTexts}>
            <FormattedMessage id="SectionHero.subTitle" />
          </div> */}
      </div>
      {!isHideButton && (
        <div className={css.searchBtnWrapper}>
          <NamedLink name="SearchPage" className={css.heroButton}>
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
        </div>
      )}
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  isHideButton: false,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  isHideButton: bool,
};

export default SectionHero;
