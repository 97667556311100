import React, { useEffect, useState } from 'react';
import css from './EditListingClassForm.css';
import { Form as FinalForm } from 'react-final-form';
import { Form } from '../../components';
import { useIntl } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import Tabs from './Tabs';
import isEqual from 'lodash/isEqual';
import { func, string, object } from 'prop-types';

const EditListingClassForm = ({
  className,
  onSubmit,
  initialValues = {},
  classId = null,
  onSaveClass,
  ...rest
}) => {
  const [currentValues, setCurrentValues] = useState(initialValues);
  const intl = useIntl();

  useEffect(() => {
    if (!isEqual(initialValues, currentValues)) {
      setCurrentValues(initialValues);
    }
  }, [initialValues, currentValues]);

  return (
    <FinalForm
      initialValues={currentValues}
      onSubmit={values => {
        setCurrentValues(values);
        onSubmit({
          ...values,
          classId,
        });
      }}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, form, invalid }) => {
        return (
          <Form onSubmit={handleSubmit} className={classNames(css.root, className)}>
            <FieldArray
              name="classes"
              render={({ fields, meta }) => {
                if (fields.length)
                  return (
                    <div>
                      <Tabs
                        fields={fields}
                        intl={intl}
                        form={form}
                        onSaveClass={onSaveClass}
                        meta={meta}
                        invalid={invalid}
                        {...rest}
                      />
                    </div>
                  );
                return <div onClick={() => fields.push(undefined)}>Add class</div>;
              }}
            />
          </Form>
        );
      }}
    />
  );
};

EditListingClassForm.defaultProps = {
  initialValues: {},
  classId: null,
};

EditListingClassForm.propTypes = {
  className: string,
  onSubmit: func,
  initialValues: object,
  classId: string,
  onSaveClass: func,
};

export default EditListingClassForm;
