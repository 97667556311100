export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    queryParamNames: ['dates', 'minDuration'],
    config: {
      searchTimeZone: 'Etc/UTC',
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    queryParamNames: ['price'],
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {},
  },
  {
    id: 'yogaStyles',
    label: 'Yoga styles',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_yogaType'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'open', label: 'Open', labelId: 'YogaType.open,' },
        { key: 'ashtanga', label: 'Ashtanga', labelId: 'YogaType.ashtanga' },
        { key: 'jivamukti', label: 'Jivamukti', labelId: 'YogaType.jivamukti' },
        { key: 'vinyasa', label: 'Vinyasa', labelId: 'YogaType.vinyasa' },
        { key: 'hatha', label: 'Hatha', labelId: 'YogaType.hatha' },
        { key: 'katonah', label: 'Katonah', labelId: 'YogaType.katonah' },
        {
          key: 'spiritualWorrior',
          label: 'Spiritual Worrior',
          labelId: 'YogaType.spiritualWorrior',
        },
        { key: 'restorative', label: 'Restorative', labelId: 'YogaType.restorative' },
        { key: 'yinYoga', label: 'Yin yoga', labelId: 'YogaType.yinYoga' },
        { key: 'kundalini', label: 'Kundalini', labelId: 'YogaType.kundalini' },
        { key: 'prenatal', label: 'Prenatal', labelId: 'YogaType.prenatal' },
        { key: 'postnatal', label: 'Postnatal', labelId: 'YogaType.postnatal' },
        {
          key: 'yogaForTheElderly',
          label: 'Yoga for the elderly',
          labelId: 'YogaType.yogaForTheElderly',
        },
        { key: 'yogaForKids', label: 'Yoga for kids', labelId: 'YogaType.yogaForKids' },
      ],
    },
  },
  {
    id: 'certificate',
    label: 'Certificate',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_certificate'],
    config: {
      options: [
        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: '200h', label: 'Registered yoga teacher 200h' },
        { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
  {
    id: 'classType',
    label: 'Class Type',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_classType'],
    config: {
      options: [
        { key: 'digital', label: 'Digital', labelId: 'ClassTypes.digital' },
        { key: 'physical', label: 'Physical', labelId: 'ClassTypes.physical' },
        { key: 'hybrid', label: 'Hybrid', labelId: 'ClassTypes.hybrid' },
      ],
    },
  },
  {
    id: 'languages',
    label: 'Languages',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_languages'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'english', label: 'English', labelId: 'Languages.english' },
        { key: 'french', label: 'French', labelId: 'Languages.french' },
        { key: 'german', label: 'German', labelId: 'Languages.german' },
        { key: 'italian', label: 'Italian', labelId: 'Languages.italian' },
        { key: 'spanish', label: 'Spanish', labelId: 'Languages.spanish' },
      ],
    },
  },
  {
    id: 'level',
    label: 'Level',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_level'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'all', label: 'Open to all levels', labelId: 'Levels.allLevels' },
        { key: 'beginner', label: 'Beginner', labelId: 'Levels.beginner' },
        { key: 'intermediate', label: 'Intermediate', labelId: 'Levels.intermediate' },
        { key: 'advanced', label: 'Advanced', labelId: 'Levels.advanced' },
      ],
    },
  },
];

export const classTodayFilters = [
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {},
  },
  {
    id: 'yogaStyles',
    label: 'Yoga styles',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_yogaType'],
    config: {
      searchMode: '',
      options: [
        { key: 'open', label: 'Open', labelId: 'YogaType.open,' },
        { key: 'ashtanga', label: 'Ashtanga', labelId: 'YogaType.ashtanga' },
        { key: 'jivamukti', label: 'Jivamukti', labelId: 'YogaType.jivamukti' },
        { key: 'vinyasa', label: 'Vinyasa', labelId: 'YogaType.vinyasa' },
        { key: 'hatha', label: 'Hatha', labelId: 'YogaType.hatha' },
        { key: 'katonah', label: 'Katonah', labelId: 'YogaType.katonah' },
        {
          key: 'spiritualWorrior',
          label: 'Spiritual Worrior',
          labelId: 'YogaType.spiritualWorrior',
        },
        { key: 'restorative', label: 'Restorative', labelId: 'YogaType.restorative' },
        { key: 'yinYoga', label: 'Yin yoga', labelId: 'YogaType.yinYoga' },
        { key: 'kundalini', label: 'Kundalini', labelId: 'YogaType.kundalini' },
        { key: 'prenatal', label: 'Prenatal', labelId: 'YogaType.prenatal' },
        { key: 'postnatal', label: 'Postnatal', labelId: 'YogaType.postnatal' },
        {
          key: 'yogaForTheElderly',
          label: 'Yoga for the elderly',
          labelId: 'YogaType.yogaForTheElderly',
        },
        { key: 'yogaForKids', label: 'Yoga for kids', labelId: 'YogaType.yogaForKids' },
      ],
    },
  },
  {
    id: 'level',
    label: 'Level',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_level'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'all', label: 'Open to all levels', labelId: 'Levels.allLevels' },
        { key: 'beginner', label: 'Beginner', labelId: 'Levels.beginner' },
        { key: 'intermediate', label: 'Intermediate', labelId: 'Levels.intermediate' },
        { key: 'advanced', label: 'Advanced', labelId: 'Levels.advanced' },
      ],
    },
  },
  {
    id: 'languages',
    label: 'Languages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_languages'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'english', label: 'English', labelId: 'Languages.english' },
        { key: 'french', label: 'French', labelId: 'Languages.french' },
        { key: 'german', label: 'German', labelId: 'Languages.german' },
        { key: 'italian', label: 'Italian', labelId: 'Languages.italian' },
        { key: 'spanish', label: 'Spanish', labelId: 'Languages.spanish' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const lengths = [
  { key: '30', label: '30 min', labelId: 'Lengths.30Min' },
  { key: '45', label: '45 min', labelId: 'Lengths.45Min' },
  { key: '60', label: '60 min', labelId: 'Lengths.60Min' },
  { key: '75', label: '75 min', labelId: 'Lengths.75Min' },
  { key: '90', label: '90 min', labelId: 'Lengths.90Min' },
];

export const topicContactUsConfig = [
  {
    key: 'becomeATeacher',
    labelId: 'Config.topic.becomeATeacher',
  },
  {
    key: 'feedbackAboutBookingOrClass',
    labelId: 'Config.topic.feedbackAboutBookingOrClass',
  },
  {
    key: 'reportTechnicalProblem',
    labelId: 'Config.topic.reportTechnicalProblem',
  },
  {
    key: 'reportPaymentProblem',
    labelId: 'Config.topic.reportPaymentProblem',
  },
  {
    key: 'collaborationAndSale',
    labelId: 'Config.topic.collaborationAndSale',
  },
  {
    key: 'advertiseYourEvent',
    labelId: 'Config.topic.advertiseYourEvent',
  },
  {
    key: 'other',
    labelId: 'Config.topic.other',
  },
];

export const listingPricing = {
  private: {
    digital: [
      { key: 6000, label: 'CHF 60' },
      { key: 8000, label: 'CHF 80' },
      { key: 10000, label: 'CHF 100' },
    ],
    physical: [
      { key: 8000, label: 'CHF 80' },
      { key: 10000, label: 'CHF 100' },
      { key: 12000, label: 'CHF 120' },
    ],
  },
  normal: [
    { key: 500, label: 'CHF 5' },
    { key: 1000, label: 'CHF 10' },
    { key: 1500, label: 'CHF 15' },
    { key: 2000, label: 'CHF 20' },
    { key: 2500, label: 'CHF 25' },
  ],
};

export const AVAILABILITY_STEP = 15;
export const MON = 'mon';
export const TUE = 'tue';
export const WED = 'wed';
export const THU = 'thu';
export const FRI = 'fri';
export const SAT = 'sat';
export const SUN = 'sun';

export const imageDomain = 'https://t4y-public-files.s3.eu-north-1.amazonaws.com';
