import React, { useEffect, useMemo, useRef } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { types } from 'util/sdkLoader';
import css from './BookingPanel.css';
import get from 'lodash/get';
import { listingIsEvent, listingIsPrivateClass } from 'util/data';
const { Money } = types;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    openBookModal,
    selectedClassId,
  } = props;
  const formRef = useRef();
  const previousListing = useRef(null);
  const isEvent = listingIsEvent(listing);
  const price = get(listing, 'attributes.price', null);

  useEffect(() => {
    const id = get(previousListing.current, 'id.uuid', null);
    const currentId = get(listing, 'id.uuid', null);
    if (currentId && id !== currentId) {
      if (formRef.current && typeof formRef.current.reset === 'function') {
        formRef.current.reset();
      }
      previousListing.current = currentId;
    }
  }, [selectedClassId]);

  let initialValues = useMemo(() => {
    if (isEvent) {
      const timeSlots = Object.values(monthlyTimeSlots)
        .reduce((prev, cur) => prev.concat(Object.values(cur)), [])
        .reduce((prev, cur) => {
          if (cur.timeSlots) {
            return prev.concat(cur.timeSlots);
          }
          return prev;
        }, []);
      const timeSlot = Array.isArray(timeSlots) ? timeSlots[0] : null;
      const start = get(timeSlot, 'attributes.start', null);
      const end = get(timeSlot, 'attributes.end', null);

      return {
        bookingStartDate: {
          date: start,
        },
        bookingStartTime: start ? start.getTime() : null,
        bookingEndTime: end ? end.getTime() : null,
        prices: price ? price.amount : 0,
      };
    }
    return {};
  }, [monthlyTimeSlots, isEvent, price]);

  if (!listing || !listing.id) return null;

  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const classLength = parseInt(get(listing, 'attributes.publicData.length', 0), 10);

  const currentListingPrices = get(listing, 'attributes.publicData.prices', []);
  const prices =
    Array.isArray(currentListingPrices) && currentListingPrices.length > 0
      ? currentListingPrices
          .map(p => new Money(p, config.currency))
          .sort((a, b) => a.amount - b.amount)
      : [price];

  return (
    <div className={classes}>
      <ModalInMobile
        className={css.modal}
        containerClassName={css.modalContainer}
        id="BookingTimeFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}>
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>
        <div className={css.bookingHeading}>
          <div className={css.desktopPriceContainer}>
            {isEvent ? (
              <>
                <div className={css.desktopPriceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
                <div className={css.desktopPerUnit}>
                  <FormattedMessage id={unitTranslationKey} />
                </div>
              </>
            ) : null}
          </div>
          <div className={css.bookingHeadingContainer}>
            <h2 className={titleClasses}>{title}</h2>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
          </div>
        </div>

        {showBookingTimeForm ? (
          <BookingTimeForm
            ref={formRef}
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.submitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            classLength={classLength}
            privateClass={listingIsPrivateClass(listing)}
            prices={prices}
            isEvent={isEvent}
            initialValues={initialValues}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed)}>
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  selectedClassId: string,
  openBookModal: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
