import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-basel',
    predictionPlace: {
      address: 'Basel',
      bounds: new LatLngBounds(
        new LatLng(47.57688094, 7.60721176),
        new LatLng(47.53575619, 7.56746976)
      ),
    },
  },
  {
    id: 'default-zurich',
    predictionPlace: {
      address: 'Zürich',
      bounds: new LatLngBounds(
        new LatLng(47.6947499800253, 8.98495199415534),
        new LatLng(47.1594420176842, 8.35777802457705)
      ),
    },
  },
  {
    id: 'default-bern',
    predictionPlace: {
      address: 'Bern',
      bounds: new LatLngBounds(
        new LatLng(47.3453208985378, 8.45507699999278),
        new LatLng(46.326520005032, 6.86149900115696)
      ),
    },
  },
  {
    id: 'default-geneva',
    predictionPlace: {
      address: 'Geneva',
      bounds: new LatLngBounds(
        new LatLng(46.364571957978, 6.31028291584972),
        new LatLng(46.1285620000364, 5.95599426104971)
      ),
    },
  },
  {
    id: 'default-milan',
    predictionPlace: {
      address: 'Milan',
      bounds: new LatLngBounds(
        new LatLng(45.6434789999527, 9.55147199515883),
        new LatLng(45.1613910173671, 8.70593002007672)
      ),
    },
  },
];
