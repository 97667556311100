import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, propTypes, LINE_ITEM_UNITS } from '../../util/types';
import { Button, FieldDateRangeInput, FieldSelect, ListingLink } from '../../components';

import css from './EditListingAvailabilityPanel.css';
import { Form } from 'react-final-form';
import FieldTimeZoneSelect from 'components/FieldTimeZoneSelect/FieldTimeZoneSelect';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { bookingDatesRequired, required } from 'util/validators';

const moment = extendMoment(Moment);

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial values
const createInitialValues = (availabilityPlan, exceptions) => {
  const { timezone } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  const startAndEndMaybe =
    exceptions && exceptions[0]
      ? {
          exceptions: {
            startDate: exceptions[0].attributes.start,
            endDate: exceptions[0].attributes.end,
          },
          startTime: moment(exceptions[0].attributes.start).valueOf(),
          endTime: moment(exceptions[0].attributes.end).valueOf(),
        }
      : {};
  return {
    timezone: tz,
    ...startAndEndMaybe,
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => {
  const { startTime, endTime } = values;

  return {
    availabilityPlan: {
      type: 'availability-plan/time',
      timezone: values.timezone,
      entries: createEntriesFromSubmitValues(values),
    },
    publicData: {
      length: moment(timestampToDate(endTime))
        .diff(moment(timestampToDate(startTime)), 'minutes')
        .toString(),
      startTime,
      endTime,
    },
  };
};
//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////

const createTimeOptions = date => {
  const start = moment(date).startOf('day');
  const end = start.clone().add(1, 'day').startOf('day');

  return Array.from(moment.range(start, end).by('minutes', { step: 30 }));
};

const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    onAddAvailabilityException,
    onSubmit,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
  } = props;
  // Hooks
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan, availabilityExceptions);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);

    return onSubmit(createAvailabilityPlan(values)).catch(e => {
      // Don't close modal if there was an error
    });
  };

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime, availabilityPlan } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;
    const tz = availabilityPlan.timezone;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: moment.tz(parseInt(exceptionStartTime, 10), tz).toDate(),
      end: moment.tz(parseInt(exceptionEndTime, 10), tz).toDate(),
    }).catch(e => {
      // Don't close modal if there was an error
    });
  };

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: <ListingLink listing={listing} />,
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      <Form
        initialValues={initialValues}
        onSubmit={values => {
          Promise.all([
            handleSubmit(values),
            saveException({
              availability: 'available',
              exceptionStartTime: values.startTime,
              exceptionEndTime: values.endTime,
              availabilityPlan: createAvailabilityPlan(values),
            }),
          ]).then(() => {
            onNextTab();
          });

          // onNextTab();
        }}
        render={({ invalid, handleSubmit, values }) => {
          const { startDate, endDate } = values.exceptions || {};
          return (
            <div className={css.form}>
              <div className={css.field}>
                <FieldTimeZoneSelect id="timezone" name="timezone" label="Your timezone" />
              </div>
              <div className={css.field}>
                <FieldDateRangeInput
                  className={css.exceptions}
                  name="exceptions"
                  unitType={LINE_ITEM_UNITS}
                  minimumNights={0}
                  endDateId="exceptions.endDate"
                  endDateLabel="End Date"
                  startDateId="exceptions.startDate"
                  startDateLabel="Start Date"
                  borderClassName={css.border}
                  validate={bookingDatesRequired(
                    'Please provide a start date',
                    'Please provide a end date'
                  )}
                />
              </div>
              <div className={classNames(css.field, css.row)}>
                <div>
                  {startDate && (
                    <FieldSelect
                      name="startTime"
                      label="Start Time"
                      id="startTime"
                      validate={required(' ')}>
                      <option disabled value="">
                        Select a start time
                      </option>
                      {createTimeOptions(startDate).map(t => (
                        <option value={t.valueOf()} key={t.valueOf()}>
                          {t.format('HH:mm')}
                        </option>
                      ))}
                    </FieldSelect>
                  )}
                </div>
                <div>
                  {endDate && (
                    <FieldSelect
                      name="endTime"
                      label="End Time"
                      id="endTime"
                      validate={required(' ')}>
                      <option disabled value="">
                        Select a end time
                      </option>
                      {createTimeOptions(endDate).map(t => (
                        <option value={t.valueOf()} key={t.valueOf()}>
                          {t.format('HH:mm')}
                        </option>
                      ))}
                    </FieldSelect>
                  )}
                </div>
              </div>
              <Button
                className={css.goToNextTabButton}
                onClick={handleSubmit}
                disabled={invalid}
                inProgress={updateInProgress}>
                {submitButtonText}
              </Button>
            </div>
          );
        }}
      />

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {/* {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          onClick={onNextTab}
          disabled={isNextButtonDisabled}>
          {submitButtonText}
        </Button>
      ) : null} */}
      {/* {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal>
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={isEventListing ? currentListing.attributes.title : null}
            availabilityPlan={availabilityPlan}
            weekdays={WEEKDAYS}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            inProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null} */}
      {/* {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal>
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </Modal>
      ) : null} */}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
