import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import moment from 'moment';

import css from './ListingPage.css';
import { timestampToDate } from 'util/dates';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, isEvent, publicData } = props;
  const { startTime, endTime } = publicData || {};
  const title = isEvent ? 'ListingPage.event.descriptionTitle' : 'ListingPage.descriptionTitle';
  const startMaybe = startTime
    ? moment(timestampToDate(startTime)).format('ddd, h:mm a, MMM d')
    : null;
  const endMaybe = endTime ? moment(timestampToDate(endTime)).format('ddd, h:mm a, MMM d') : null;
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id={title} />
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      {startMaybe && endMaybe ? (
        <p className={css.description}>
          <FormattedMessage
            id="Event.descriptionMaybe"
            values={{ start: startMaybe, end: endMaybe }}
          />
        </p>
      ) : null}
    </div>
  ) : null;
};

SectionDescriptionMaybe.propTypes = {
  description: PropTypes.string,
  isEvent: PropTypes.bool,
  publicData: PropTypes.object,
};

export default SectionDescriptionMaybe;
