import React, { useState } from 'react';
import css from './SearchResultByWeek.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ClassListingCard } from '../index';
import { FRI, MON, SAT, SUN, THU, TUE, WED } from '../../marketplace-custom-config';
import classNames from 'classnames';
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { bool, func, number, object, oneOf, shape, string } from 'prop-types';
const moment = extendMoment(Moment);

const today = moment(new Date());
const week = [SUN, MON, TUE, WED, THU, FRI, SAT];

const ResultByDate = props => {
  const { dayOfWeek, listings, date, onClickLabel, selectedDate, isPassed, index } = props;
  const labelClasses = classNames(css.dateLabel, {
    [css.selectedLabel]: selectedDate === dayOfWeek,
    [css.disabled]: isPassed,
  });

  return (
    <div className={css.week}>
      <p className={labelClasses} onClick={() => onClickLabel(dayOfWeek, index)}>
        <FormattedMessage id={`SearchResultByWeek.${dayOfWeek}Day`} />
        ,&nbsp;
        {date.format('DD/MM')}&nbsp;
      </p>
      <div className={css.classList}>
        {listings && !isPassed ? (
          Object.keys(listings)
            .sort()
            .map((key, i) => (
              <ClassListingCard
                key={i}
                time={key}
                className={css.classListing}
                listings={listings[key]}
              />
            ))
        ) : isPassed ? (
          <p className={css.dayIsPassed}>
            <FormattedMessage id={'SearchResultByWeek.dayIsPassed'} />
          </p>
        ) : null}
      </div>
    </div>
  );
};

ResultByDate.propTypes = {
  dayOfWeek: oneOf(week),
  listings: object,
  date: object,
  onClickLabel: func,
  selectedDate: oneOf(week),
  isPassed: bool,
  index: number,
};

const SearchResultByWeek = props => {
  const [selectedDate, setSelectedDate] = useState({ key: SUN, index: 0 });
  const { rootClassName, className, listings } = props;
  const classes = classNames(rootClassName || css.root, className);
  // const listingByWeek = listingByDayOfWeek(listings);
  const weekRange = Array.from(today.range('week').by('days', { step: 1 }));
  const listingsBySelectedDate = listings[selectedDate.key];
  const selectedDateIsPassed = weekRange[selectedDate.index].isBefore(today, 'day');

  return (
    <div className={classes}>
      <div className={css.weekList}>
        {week.map((date, i) => (
          <ResultByDate
            index={i}
            date={weekRange[i]}
            key={i}
            dayOfWeek={date}
            listings={listings[date]}
            onClickLabel={(date, index) => setSelectedDate({ key: date, index })}
            selectedDate={selectedDate.key}
            isPassed={weekRange[i].isBefore(today, 'day')}
          />
        ))}
      </div>
      <div className={css.mobileListingList}>
        {listingsBySelectedDate && !selectedDateIsPassed ? (
          Object.keys(listingsBySelectedDate)
            .sort()
            .map((key, i) => {
              return (
                <ClassListingCard
                  key={i}
                  time={key}
                  className={css.classListing}
                  listings={listingsBySelectedDate[key]}
                />
              );
            })
        ) : selectedDateIsPassed ? (
          <p className={css.dayIsPassed}>
            <FormattedMessage id={'SearchResultByWeek.dayIsPassed'} />
          </p>
        ) : null}
      </div>
    </div>
  );
};

SearchResultByWeek.defaultProps = {
  listings: {},
};

SearchResultByWeek.propTypes = {
  rootClassName: string,
  className: string,
  listings: shape({
    sun: object,
    mon: object,
    tue: object,
    wed: object,
    thu: object,
    fri: object,
    sat: object,
  }),
};

export default injectIntl(SearchResultByWeek);
