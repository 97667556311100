import React from 'react';
import { ExternalLink } from '../../components';

import css from './ContactPage.css';

const ContactInformation = () => {
  return (
    <div className={css.contactInfoContainer}>
      {/* <h2 className={css.contactInfoTitle}> Contact Information </h2> */}
      <div className={css.contactInfo}>
        <p> Time for Yourself Online Yoga GmbH </p>
        <p> Ochsengasse 6, Allschwil, 4123, Switzerland </p>
        <p> Phone: +41 61 482 2121 </p>
        <p>
          {' '}
          Email:
          <ExternalLink href="mailto:info@timeforyourself.online">
            {' '}
            info@timeforyourself.online{' '}
          </ExternalLink>
        </p>
      </div>
    </div>
  );
};

export default ContactInformation;
