import React from 'react';
import css from './ClassListingCard.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ensureListing } from '../../util/data';
import { NamedLink } from '../index';
import { createSlug } from '../../util/urlHelpers';
import { arrayOf, string } from 'prop-types';
import { propTypes } from '../../util/types';

const ClassListingCard = props => {
  const { intl, listings, rootClassName, className, time } = props;
  const classes = classNames(rootClassName || css.root, className);

  return listings
    ? listings.map((listing, i) => {
        const ensuredListing = ensureListing(listing);
        const {
          title,
          publicData: { length, yogaType, teacherListingId, languages },
        } = ensuredListing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            key={i}
            className={classes}
            name="ListingPage"
            params={{ id: teacherListingId, slug }}
            to={{ state: { classId: listing.id.uuid } }}>
            <div>
              <p className={css.time}>
                {time} ● {length} {intl.formatMessage({ id: 'ClassListingCard.minute' })}
              </p>
              <p>{intl.formatMessage({ id: `YogaType.${yogaType}` })}</p>
              <p className={css.languages}>
                {languages.map((l, i) => (
                  <FormattedMessage
                    id={`Languages.${l}`}
                    values={{ prefix: i !== languages.length - 1 ? ',' : '' }}
                  />
                ))}
              </p>
              <p>{title}</p>
            </div>
          </NamedLink>
        );
      })
    : null;
};

ClassListingCard.propTypes = {
  listings: arrayOf(propTypes.listing),
  rootClassName: string,
  className: string,
  time: string,
};

export default injectIntl(ClassListingCard);
