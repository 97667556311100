export const loginIntercomUser = ({ currentUser, hasPublishedListings }) => {
  if (typeof window.Intercom === 'undefined') {
    console.log('Intercom is not ready');
    return;
  }

  if (!currentUser || !currentUser.id) {
    return;
  }

  const profile = currentUser.attributes.profile;

  const intercomSettings = window.intercomSettings || {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  };

  intercomSettings.user_id = currentUser.id.uuid;
  intercomSettings.email = currentUser.attributes.email;
  intercomSettings.phone = profile.protectedData.phoneNumber;
  intercomSettings.name = `${profile.firstName} ${profile.lastName}`;
  intercomSettings.role = hasPublishedListings ? 'teacher' : 'student';

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.setItem(
      'uvk',
      currentUser.attributes.profile.privateData.userVerificationKey
    );
  }
};

export const logoutIntercomUser = () => {
  if (typeof window.Intercom === 'undefined') {
    return;
  }

  const intercomSettings = {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: null,
    email: null,
    phone: null,
    name: null,
    role: null,
  };

  window.Intercom('shutdown');
  window.Intercom('boot', intercomSettings);

  if (typeof window.localStorage !== 'undefined') {
    window.localStorage.removeItem('uvk');
  }
};
