import React from 'react';
import { bool, func } from 'prop-types';
import { propTypes } from '../../util/types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { PrimaryButton } from '../../components';
import { getStartHours, getEndHours } from '../../util/dates';

import css from './ClassJitsyPage.css';

const initializeJitsyRoomName = ({ listingType, yogaStyles, yogaType, length, title }) => {
  if (listingType === 'event') {
    if (yogaStyles[0]) return `${yogaStyles[0]} yoga`;
    else return <FormattedMessage id="ClassJitsyPage.unknownClass" />;
  } else {
    if (yogaType && length && title) return `${yogaType} yoga - ${length} mins with ${title}`;
    else return <FormattedMessage id="ClassJitsyPage.unknownClass" />;
  }
};

const initializeJitsyRoomDetail = ({
  listingType,
  classType,
  startTime,
  endTime,
  classTypeElement,
  timeElement,
}) => {
  if (listingType === 'event') {
    return <FormattedMessage id="ClassJitsyPage.eventClass" />;
  } else {
    if (classType && startTime && endTime)
      return (
        <FormattedMessage
          id="ClassJitsyPage.classDetails"
          values={{ classTypeElement, timeElement }}
        />
      );
    else return <FormattedMessage id="ClassJitsyPage.lackOfInformation" />;
  }
};

const SectionWaitingRoom = props => {
  const { intl, transaction, isTeacherJoined, joinClass } = props;

  const { publicData: listingData, title, availabilityPlan } =
    (transaction && transaction.listing && transaction.listing.attributes) || {};
  const { displayStart, displayEnd } =
    (transaction && transaction.booking && transaction.booking.attributes) || {};
  const { yogaType, length, classType, listingType, yogaStyles } = listingData || {};
  const { timezone } = availabilityPlan || {};

  const startHours =
    timezone && getStartHours(intl, timezone, displayStart, displayEnd, parseInt(length));
  const endHours =
    timezone && getEndHours(intl, timezone, displayStart, displayEnd, parseInt(length));
  const startTime = startHours && startHours[0].timeOfDay;
  const endTime = endHours && endHours[0].timeOfDay;

  const jitsyRoomName = initializeJitsyRoomName({
    listingType,
    yogaStyles,
    yogaType,
    length,
    title,
  });

  const classTypeElement = <span className={css.details}>{classType} </span>;
  const timeElement = (
    <span className={css.details}>
      {' '}
      {startTime} - {endTime}{' '}
    </span>
  );

  const jitsyRoomDetail = initializeJitsyRoomDetail({
    listingType,
    classType,
    startTime,
    endTime,
    classTypeElement,
    timeElement,
  });

  return (
    <>
      {isTeacherJoined && (
        <div className={css.popupWrapper}>
          <div className={css.notification}>
            <img
              src="https://t4y-public-files.s3.eu-north-1.amazonaws.com/landing-page/icon-transparent.png"
              alt="icon"
              className={css.iconNotification}
            />
            <div className={css.alert}>
              <FormattedMessage id="ClassJitsyPage.notification" />
            </div>
            <PrimaryButton className={css.button} onClick={joinClass}>
              <FormattedMessage id="ClassJitsyPage.joinYourClassBtn" />
            </PrimaryButton>
          </div>
        </div>
      )}
      <div className={css.waitingRoomTitleContainer}>
        <div className={css.animatedLogo}>
          <img
            src="https://t4y-public-files.s3.eu-north-1.amazonaws.com/waiting-room/T4Y_WHITE.gif"
            alt="logo"
          />
        </div>
        <div className={css.title}>
          <h2>
            <FormattedMessage id="ClassJitsyPage.jitsyRoomName" values={{ jitsyRoomName }} />
          </h2>
          <p> {jitsyRoomDetail} </p>
        </div>
      </div>
      <div className={css.advertisement}>
        <video autoPlay loop muted preload="metadata">
          <source
            src="https://t4y-public-files.s3.eu-north-1.amazonaws.com/videos/SALA+DE+ESPERA-2-WEB.mp4"
            type="video/mp4"
          />
          <FormattedMessage id="ClassJitsyPage.cantLoadVideoSource" />
        </video>
        <audio autoPlay controls loop preload="auto" className={css.hidenAudio}>
          <source
            src="https://t4y-public-files.s3.eu-north-1.amazonaws.com/audios/SALA+DE+ESPERA-2-WEB.mp3"
            type="audio/mpeg"
          />
        </audio>
      </div>
    </>
  );
};

SectionWaitingRoom.defaultProps = {
  transaction: null,
  isTeacherJoined: null,
  joinClass: null,
};

SectionWaitingRoom.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
  transaction: propTypes.transaction,
  isTeacherJoined: bool.isRequired,
  joinClass: func.isRequired,
};

export default SectionWaitingRoom;
