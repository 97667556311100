import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Logo.css';
import logoLarge from '../../assets/logoLarge.png';

const Logo = props => {
  const { className, rootClassName } = props;
  return (
    <img
      src={logoLarge}
      className={classNames(rootClassName || css.root, className)}
      alt="Time For Yourself"
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
