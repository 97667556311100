import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 21, 2020</p>

      <p>
        It is our policy to respect your privacy regarding any information we may collect while
        operating our websites.
      </p>

      <p>
        Our policy in easy to read format:
        <br />• We don’t ask you for your personal data unless we truly need it.
        <br />• We don’t share your personal data with anyone except to comply with the law, develop
        our products, or protect our rights.
        <br />• We don’t store personal data on our servers unless required for the on-going
        operation of one of our services.
      </p>

      <h2>Website Visitors</h2>

      <p>
        Like most website operators, Time for Yourself Online collects non-personally-identifying
        information of the sort that web browsers and servers typically make available, such as the
        browser type, language preference, referring site, and the date and time of each visitor
        request. Time for Yourself Online’s purpose in collecting non-personally identifying
        information is to better understand how Time for Yourself Online’s visitors use its website.
        From time to time, Time for Yourself Online may release non-personally-identifying
        information in the aggregate, e.g., by publishing a report on trends in the usage of its
        website. Time for Yourself Online also collects potentially personally-identifying
        information like Internet Protocol (IP) addresses for logged in users and for users leaving
        comments on WordPress.com blogs. Time for Yourself Online only discloses logged in user and
        commenter IP addresses under the same circumstances that it uses and discloses
        personally-identifying information as described below, except that blog commenter IP
        addresses and email addresses are visible and disclosed to the administrators of the blog
        where the comment was left.
      </p>
      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to Time for Yourself Online’s websites choose to interact with Time for
        Yourself Online in ways that require Time for Yourself Online to gather
        personally-identifying information. The amount and type of information that Time for
        Yourself Online gathers depends on the nature of the interaction. In each case, Time for
        Yourself Online collects such information only insofar as is necessary or appropriate to
        fulfill the purpose of the visitor’s interaction with Time for Yourself Online. Time for
        Yourself Online does not disclose personally-identifying information other than as described
        below. And visitors can always refuse to supply personally-identifying information, with the
        caveat that it may prevent them from engaging in certain website-related activities.
      </p>
      <h2>Aggregated Statistics</h2>
      <p>
        Time for Yourself Online may collect statistics about the behavior of visitors to its
        websites. Time for Yourself Online may display this information publicly or provide it to
        others. However, Time for Yourself Online does not disclose personally-identifying
        information other than as described below.
      </p>
      <h2>Protection of Certain Personally-Identifying Information</h2>
      <p>
        Time for Yourself Online discloses potentially personally-identifying and
        personally-identifying information only to those of its employees, contractors and
        affiliated organizations that (i) need to know that information in order to process it on
        Time for Yourself Online’s behalf or to provide services available at Time for Yourself
        Online’s websites, and (ii) that have agreed not to disclose it to others. Some of those
        employees, contractors and affiliated organizations may be located outside of your home
        country; by using Time for Yourself Online’s websites, you consent to the transfer of such
        information to them. Time for Yourself Online will not rent or sell potentially
        personally-identifying and personally-identifying information to anyone. Other than to its
        employees, contractors and affiliated organizations, as described above, Time for Yourself
        Online discloses potentially personally-identifying and personally-identifying information
        only in response to a subpoena, court order or other governmental request, or when Time for
        Yourself Online believes in good faith that disclosure is reasonably necessary to protect
        the property or rights of Time for Yourself Online, third parties or the public at large. If
        you are a registered user of Time for Yourself Online website and have supplied your email
        address, Time for Yourself Online may occasionally send you an email to tell you about new
        features, solicit your feedback, or just keep you up to date with what’s going on with Time
        for Yourself Online and our products. We expect to keep this type of email to a minimum. If
        you send us a request (for example via a support email or via one of our feedback
        mechanisms), we reserve the right to publish it in order to help us clarify or respond to
        your request or to help us support other users. Time for Yourself Online takes all measures
        reasonably necessary to protect against the unauthorized access, use, alteration or
        destruction of potentially personally-identifying and personally-identifying information.
      </p>
      <h2>Cookies</h2>
      <p>
        A cookie is a string of information that a website stores on a visitor’s computer, and that
        the visitor’s browser provides to the website each time the visitor returns. Time for
        Yourself Online uses cookies to help Time for Yourself Online identify and track visitors,
        their usage of Time for Yourself Online website, and their website access preferences. Time
        for Yourself Online visitors who do not wish to have cookies placed on their computers
        should set their browsers to refuse cookies before using Time for Yourself Online’s
        websites, with the drawback that certain features of Time for Yourself Online’s websites may
        not function properly without the aid of cookies.
      </p>
      <h2>Business Transfers</h2>
      <p>
        If Time for Yourself Online or substantially all of its assets, were acquired, or in the
        unlikely event that Time for Yourself Online goes out of business or enters bankruptcy, user
        information would be one of the assets that is transferred or acquired by a third party. You
        acknowledge that such transfers may occur, and that any acquirer of Time for Yourself Online
        may continue to use your personal information as set forth in this policy.
      </p>
      <h2>Ads</h2>
      <p>
        Ads appearing on any of our websites may be delivered to users by advertising partners, who
        may set cookies. These cookies allow the ad server to recognize your computer each time they
        send you an online advertisement to compile information about you or others who use your
        computer. This information allows ad networks to, among other things, deliver targeted
        advertisements that they believe will be of most interest to you. This Privacy Policy covers
        the use of cookies by Time for Yourself Online and does not cover the use of cookies by any
        advertisers.
      </p>
      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Time for Yourself Online may change its
        Privacy Policy from time to time, and in Time for Yourself Online's sole discretion. Time
        for Yourself Online encourages visitors to frequently check this page for any changes to its
        Privacy Policy. Your continued use of this site after any change in this Privacy Policy will
        constitute your acceptance of such change.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
