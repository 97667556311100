/**
 * A text field with phone number formatting. By default uses formatting
 * rules defined in the fiFormatter.js file. To change the formatting
 * provide alternative implementations for the format and parse functions
 * that are passed to the input field.
 */
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import css from './FieldPhoneNumberInput.css';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import ValidationError from 'components/ValidationError/ValidationError';

const FieldPhoneNumberInputComp = ({ className, label, id, input, meta }) => {
  const { onChange, onBlur, onFocus, value } = input;
  const { valid, error } = meta;
  return (
    <div className={classNames(css.root, className)}>
      {label && <label>{label}</label>}
      <IntlTelInput
        fieldId={id}
        preferredCountries={['ch']}
        inputClassName={classNames(css.input, {
          [css.inputSuccess]: valid,
          [css.inputError]: !!error,
        })}
        onPhoneNumberChange={(_, phoneNumber) => {
          onChange(phoneNumber);
        }}
        onPhoneNumberFocus={onFocus}
        onPhoneNumberBlur={onBlur}
        autoComplete
        nationalMode={false}
        value={value}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldPhoneNumberInput = props => <Field component={FieldPhoneNumberInputComp} {...props} />;

export default FieldPhoneNumberInput;
