import React, { useState, useEffect } from 'react';
// import io from 'socket.io-client';
import { propTypes } from '../../util/types';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  NamedRedirect,
} from '../../components';
import SectionWaitingRoom from './SectionWaitingRoom';
import { TopbarContainer } from '../../containers';
import { loadData } from './ClassJitsyPage.duck';

import css from './ClassJitsyPage.css';
import get from 'lodash/get';

const STUDENT_TOOLBAR_BUTTONS = [
  'microphone',
  'camera',
  'closedcaptions',
  'fullscreen',
  'fodeviceselection',
  'hangup',
  'chat',
  'settings',
  'raisehand',
  'videoquality',
  'filmstrip',
  'feedback',
  'etherpad',
  'tileview',
  'videobackgroundblur',
  'help',
];

const TEACHER_TOOLBAR_BUTTONS = [
  'microphone',
  'camera',
  'closedcaptions',
  'desktop',
  'embedmeeting',
  'fullscreen',
  'fodeviceselection',
  'hangup',
  'profile',
  'chat',
  'recording',
  'livestreaming',
  'etherpad',
  'sharedvideo',
  'settings',
  'raisehand',
  'videoquality',
  'filmstrip',
  'feedback',
  'stats',
  'shortcuts',
  'tileview',
  'videobackgroundblur',
  'download',
  'help',
  'mute-everyone',
  'security',
];

const TEACHER_ROLE = 'teacher';
const STUDENT_ROLE = 'student';

const ClassJitsyPageComponent = props => {
  const { currentUser, transaction, intl } = props;
  const [socket, setSocket] = useState(null);
  const [isHangedUp, setIsHangedUp] = useState(false);
  const [isTeacherJoined, setIsTeacherJoined] = useState(false);
  const [isStudentJoined, setIsStudentJoined] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  //todo: need classId [listingId+time], userId, role here

  const currentUserId = get(currentUser, 'id.uuid');
  const customerId = get(transaction, 'customer.id.uuid');
  const providerId = get(transaction, 'provider.id.uuid');
  const jitsyMeetLink = get(transaction, 'attributes.protectedData.jitsyMeetLink');

  const isStudent = currentUserId && currentUserId === customerId;
  const isTeacher = currentUserId && currentUserId === providerId;

  const roleName = isTeacher ? TEACHER_ROLE : STUDENT_ROLE;

  useEffect(() => {
    if (!currentUserId || !jitsyMeetLink || !roleName) {
      return;
    }
    const socketUrl = `wss://645wm6unjd.execute-api.eu-central-1.amazonaws.com/production?userId=${currentUserId}&classId=${jitsyMeetLink}&role=${roleName}`;

    console.log({ socketUrl });

    const socket = new WebSocket(socketUrl);

    // const socket = io(
    //   'wss://645wm6unjd.execute-api.eu-central-1.amazonaws.com',
    //   { path: `/production?userId=${currentUserId}&classId=${jitsyMeetLink}&role=${roleName}` }
    // );
    //
    setSocket(socket);

    socket.onopen = () => {
      console.log('Connected to socket server');
      setIsSocketConnected(true);
    };

    socket.onmessage = event => {
      const data = JSON.parse(event.data);
      console.log({ event, data });

      if (data.teacherJoined) {
        setIsTeacherJoined(true);
      }
    };
    //
    // // eslint-disable-next-line no-console
    // console.log('trying to connect...');
    // socket.on('connect', () => {
    //   // eslint-disable-next-line no-console
    //   console.log('Connected to socket server...');
    //   setIsSocketConnected(true);
    // });
  }, [currentUserId, jitsyMeetLink, roleName]);

  // socket &&
  //   socket.on(jitsyMeetLink, data => {
  //     //todo: edit received signal here
  //     const { role } = data;
  //     if (role === TEACHER_ROLE) setIsTeacherJoined(true);
  //     if (isTeacher) {
  //       socket.emit('join_class', {
  //         userId: currentUserId,
  //         classId: jitsyMeetLink,
  //         role: TEACHER_ROLE,
  //       });
  //     }
  //   });

  const userName =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.displayName;

  const { publicData: listingData, title } =
    (transaction && transaction.listing && transaction.listing.attributes) || {};
  const { yogaType, length } = listingData || {};
  const jitsyRoomName = `${yogaType}-yoga-${length}-mins-with-${title}`;

  const startConference = async () => {
    try {
      const domain = 'meet.jit.si';
      const options = {
        roomName: jitsyRoomName,
        height: '100%',
        parentNode: document.getElementById('jitsi-container'),
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          TOOLBAR_BUTTONS: isStudent
            ? STUDENT_TOOLBAR_BUTTONS
            : isTeacher
            ? TEACHER_TOOLBAR_BUTTONS
            : [],
        },
        configOverwrite: {
          disableSimulcast: false,
          prejoinPageEnabled: isTeacher ? true : isStudent && isTeacherJoined ? true : false,
          // startAudioOnly: isTeacher ? false : true,
          startWithAudioMuted: isTeacher ? false : true,
          apiLogLevels: ['info'],
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);

      api.addEventListener('readyToClose', () => {
        api.dispose();
        setIsHangedUp(true);
      });

      api.addEventListener('participantJoined', async () => {
        if (transaction && currentUserId && jitsyMeetLink && isSocketConnected) {
          socket.emit('join_class', {
            userId: currentUserId,
            classId: jitsyMeetLink,
            role: isTeacher ? TEACHER_ROLE : isStudent ? STUDENT_ROLE : '',
          });
        }
      });

      api.addEventListener('log', async object => {
        const logs = object.args;

        if (logs.includes('<u.stop>: ')) {
          api.dispose();
          setIsHangedUp(true);
        }
      });

      // Config some information
      await api.executeCommand('displayName', userName);
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  };

  const joinClass = () => {
    setIsStudentJoined(true);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.Intercom('update', {
        hide_default_launcher: true,
      });

      document.getElementById('jitsi-container').innerHTML = '';
      if (window.JitsiMeetExternalAPI) startConference();
      else alert(intl.formatMessage({ id: 'ClassJitsyPage.scriptJitsyNotLoaded' }));
    }

    return () => {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    };
    // eslint-disable-next-line
  }, [transaction]);

  const topbar = <TopbarContainer />;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'ClassJitsyPage.schemaTitle' }, { siteTitle });

  const jitsyContainerClasses = classNames(
    css.jitsyContainer,
    isTeacher || (isTeacherJoined && isStudentJoined) ? '' : css.hideJitsy
  );
  const jitsyContainer = (
    <div className={jitsyContainerClasses} id="jitsi-container">
      {isHangedUp && <NamedRedirect name="LandingPage" />}
    </div>
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={false}
      author={userName}
      contentType="website"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ClassJitsyPage',
        name: schemaTitle,
      }}>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain
          className={
            isTeacher || (isTeacherJoined && isStudentJoined) ? '' : css.staticPageWrapper
          }>
          {jitsyContainer}
          {isTeacher || (isTeacherJoined && isStudentJoined) ? null : (
            <SectionWaitingRoom
              intl={intl}
              transaction={transaction}
              isTeacherJoined={isTeacherJoined}
              joinClass={joinClass}
            />
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>{''}</LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ClassJitsyPageComponent.defaultProps = {
  transaction: null,
  currentUser: null,
};

ClassJitsyPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { transaction } = state.ClassJitsyPage;

  const getTransaction = id => {
    const ref = { id, type: 'transaction' };
    const transaction = getMarketplaceEntities(state, [ref]);
    return transaction.length === 1 ? transaction[0] : null;
  };

  const currentTransaction = getTransaction(transaction && transaction.id);

  return {
    isAuthenticated,
    currentUser,
    transaction: currentTransaction,
  };
};

const ClassJitsyPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ClassJitsyPageComponent);

ClassJitsyPage.loadData = loadData;

export default ClassJitsyPage;
