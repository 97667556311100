import React, { useEffect, useState } from 'react';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  SectionHero,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  ExternalLink,
  Footer,
} from '../../components';
import { ContactUsForm } from '../../forms';
import { sendContactUsEmail } from '../../util/ses';
import ContactInformation from './ContactInformation';

import css from './ContactPage.css';

const ContactPageComponent = ({ currentUser }) => {
  const intl = useIntl();
  const { siteFacebookPage, siteInstagramPage } = config;
  const [initialValues, setInitialValues] = useState({});
  const [sendMessageSuccess, setSendMessageSuccess] = useState(false);
  const [sendMessageInProgress, setSendMessageInPropgress] = useState(false);
  const [sendMessageError, setSendMessageError] = useState(null);

  useEffect(() => {
    const { profile, email } = currentUser && currentUser.attributes ? currentUser.attributes : {};

    currentUser &&
      setInitialValues({
        name: `${profile.firstName} ${profile.lastName}`,
        email: email,
      });
  }, [currentUser]);

  const handleSubmit = values => {
    setInitialValues(values);
    const topicType = intl.formatMessage({
      id: `Config.topic.${values.topic}`,
    });

    setSendMessageSuccess(false);
    setSendMessageInPropgress(true);
    setSendMessageError(null);
    return sendContactUsEmail({
      ...values,
      topicType,
      currentUser,
    })
      .then(res => {
        setSendMessageSuccess(true);
        setSendMessageInPropgress(false);
      })
      .catch(error => {
        setSendMessageInPropgress(false);
        setSendMessageError(error);
      });
  };

  // prettier-ignore
  return (
    <StaticPage
      title="Contact Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactPage',
        description: 'Contact Us || TIME FOR YOURSELF',
        name: 'Contact us page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} isHideButton />
          </div>

          <div className={css.space}></div>

          <div className={css.contentWrapper}>

            <div className={css.contentMain}>

              <h2 className={css.contentTitle}>
                <FormattedMessage id="ContactPage.contentTitle" />
              </h2>

              <ContactInformation />

              <ContactUsForm
                initialValues={{ ...initialValues }}
                className={css.form}
                onSubmit={handleSubmit}
                submitInProgress={sendMessageInProgress}
                sendMessageSuccess={sendMessageSuccess}
                sendMessageError={sendMessageError}
                setSendMessageError={setSendMessageError}
                setSendMessageSuccess={setSendMessageSuccess}
              />

              <p>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteInstagramPage}>Instagram</ExternalLink>.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

ContactPageComponent.defaultProps = {
  currentUser: null,
};

ContactPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const ContactPage = compose(connect(mapStateToProps), injectIntl)(ContactPageComponent);

export default ContactPage;
