import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';
import {
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  txIsAccepted,
  txIsRequested,
  txLastTransition,
  TRANSITION_PROVIDER_CANCEL,
  TRANSITION_CUSTOMER_CANCEL,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT,
  TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT,
  isInstantBooking,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_DISABLE_CUSTOMER_CANCEL,
  TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS,
  isEventBooking,
  TRANSITION_PROVIDER_CANCEL_EVENT,
  TRANSITION_CUSTOMER_CANCEL_EVENT,
} from 'util/transaction';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized

const getErrorTranslationKey = transition => {
  switch (transition) {
    case TRANSITION_ACCEPT:
      return 'TransactionPanel.acceptSaleFailed';
    case TRANSITION_DECLINE:
      return 'TransactionPanel.declineSaleFailed';
    case TRANSITION_PROVIDER_CANCEL:
    case TRANSITION_CUSTOMER_CANCEL:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT:
    case TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT:
    case TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT:
      return 'TransactionPanel.cancelSaleFailed';
    default:
      return 'TransactionPanel.transitFailed';
  }
};

const getCancelTransition = (tx, isProvider) => {
  const lastTransition = txLastTransition(tx);
  if (isProvider) {
    if (isInstantBooking(tx)) {
      return lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT
        ? TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT_INSTANT
        : null;
    } else {
      if (lastTransition === TRANSITION_ACCEPT) {
        return TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPT;
      } else if (lastTransition === TRANSITION_DISABLE_CUSTOMER_CANCEL)
        return TRANSITION_PROVIDER_CANCEL;
    }
    if (isEventBooking(tx)) {
      return TRANSITION_PROVIDER_CANCEL_EVENT;
    }
  } else {
    if (isInstantBooking(tx)) {
      return lastTransition === TRANSITION_CONFIRM_PAYMENT_INSTANT
        ? TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT_INSTANT
        : lastTransition === TRANSITION_DISABLE_PROVIDER_CANCEL_PUBLIC_CLASS
        ? TRANSITION_CUSTOMER_CANCEL
        : null;
    } else if (isEventBooking(tx)) {
      return TRANSITION_CUSTOMER_CANCEL_EVENT;
    } else {
      return lastTransition === TRANSITION_ACCEPT ? TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPT : null;
    }
  }
  return null;
};

const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    transitInProgress,
    transitError,
    onTransitTransaction,
    transaction,
    isProvider,
  } = props;

  const [activeButton, setActiveButton] = useState(null);

  const buttonsDisabled = transitInProgress;

  const onDeclineSale = () => {
    setActiveButton(TRANSITION_DECLINE);
    onTransitTransaction({
      id: transaction.id,
      transition: TRANSITION_DECLINE,
    })
      .then(() => setActiveButton(null))
      .catch(console.error);
  };

  const onAcceptSale = () => {
    setActiveButton(TRANSITION_ACCEPT);
    onTransitTransaction({
      id: transaction.id,
      transition: TRANSITION_ACCEPT,
    })
      .then(() => setActiveButton(null))
      .catch(console.error);
  };

  const onCancelSale = () => {
    const transitionCancel = getCancelTransition(transaction, isProvider);
    setActiveButton(transitionCancel);
    onTransitTransaction({
      id: transaction.id,
      transition: transitionCancel,
    })
      .then(() => setActiveButton(null))
      .catch(console.error);
  };

  const errorMessage = transitError ? (
    <p className={css.actionError}>
      <FormattedMessage id={getErrorTranslationKey(activeButton)} />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);
  if (!showButtons) return null;

  if (txIsAccepted(transaction)) {
    return (
      <div className={classes}>
        {errorMessage && <div className={css.actionErrors}>{errorMessage}</div>}

        <div className={css.actionButtonWrapper}>
          <SecondaryButton
            inProgress={transitInProgress}
            disabled={buttonsDisabled}
            onClick={onCancelSale}>
            <FormattedMessage id="TransactionPanel.cancelButton" />
          </SecondaryButton>
        </div>
      </div>
    );
  }

  if (txIsRequested(transaction))
    return (
      <div className={classes}>
        {errorMessage && <div className={css.actionErrors}>{errorMessage}</div>}
        <div className={css.actionButtonWrapper}>
          <SecondaryButton
            inProgress={activeButton === TRANSITION_DECLINE && transitInProgress}
            disabled={buttonsDisabled}
            onClick={onDeclineSale}>
            <FormattedMessage id="TransactionPanel.declineButton" />
          </SecondaryButton>
          <PrimaryButton
            inProgress={transitInProgress}
            disabled={buttonsDisabled}
            onClick={onAcceptSale}>
            <FormattedMessage id="TransactionPanel.acceptButton" />
          </PrimaryButton>
        </div>
      </div>
    );
};

export default SaleActionButtonsMaybe;
