import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { IconEdit, InlineTextButton, Modal } from '../../components';
import css from './Availability.css';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import EditListingAvailabilityPlanForm from '../EditListingAvailabilityPlanForm/EditListingAvailabilityPlanForm';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button">
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                  e.startTime
                } - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}</span>
              );
            })
          : null}
      </div>
    </div>
  );
};

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const defaultAvailabilityPlan = {
  type: 'availability-plan/time',
  timezone: defaultTimeZone(),
  entries: [
    // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
    // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
  ],
};

const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

const Availability = ({ availabilityPlan, length = 60, onSubmit }) => {
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const dispatch = useDispatch();
  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const currentAvailabilityPlan = availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(currentAvailabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);
    onSubmit(createAvailabilityPlan(values));
    setIsEditPlanModalOpen(false);
  };
  return (
    <>
      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
          </h2>
          <InlineTextButton
            type="button"
            className={css.editPlanButton}
            onClick={() => setIsEditPlanModalOpen(true)}>
            <IconEdit className={css.editPlanIcon} />{' '}
            <FormattedMessage id="EditListingAvailabilityPanel.edit" />
          </InlineTextButton>
        </header>
        <div className={css.week}>
          {WEEKDAYS.map(w => (
            <Weekday
              dayOfWeek={w}
              key={w}
              availabilityPlan={currentAvailabilityPlan}
              openEditModal={setIsEditPlanModalOpen}
            />
          ))}
        </div>
      </section>
      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityPlan"
          isOpen={isEditPlanModalOpen}
          onClose={() => setIsEditPlanModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal>
          <EditListingAvailabilityPlanForm
            formId="EditListingAvailabilityPlanForm"
            listingTitle={'your class'}
            availabilityPlan={currentAvailabilityPlan}
            weekdays={WEEKDAYS}
            length={length}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fetchErrors={{}}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default Availability;
