import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.css';

const SectionAboutTeacher = props => {
  const { publicData } = props;
  return publicData && publicData.aboutAuthor ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.aboutTeacher" />
      </h2>
      <p className={css.rules}>{publicData.aboutAuthor}</p>
    </div>
  ) : null;
};

SectionAboutTeacher.propTypes = {
  publicData: PropTypes.shape({
    aboutAuthor: PropTypes.string,
  }),
};

export default SectionAboutTeacher;
