import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './ContactUsForm.css';

const TopicSelectField = props => {
  const { name, id, categories, intl } = props;
  const categoryLabel = intl.formatMessage({
    id: 'ContactUsForm.categoryLabel',
  });
  const categoryPlaceholder = intl.formatMessage({
    id: 'ContactUsForm.categoryPlaceholder',
  });
  const categoryRequired = required(
    intl.formatMessage({
      id: 'ContactUsForm.categoryRequired',
    })
  );
  return categories ? (
    <FieldSelect
      className={css.textfield}
      name={name}
      id={id}
      label={categoryLabel}
      validate={categoryRequired}>
      <option disabled value="">
        {categoryPlaceholder}
      </option>
      {categories.map(c => (
        <option key={c.key} value={c.key}>
          {intl.formatMessage({
            id: c.labelId,
          })}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default TopicSelectField;
