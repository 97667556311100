import React from 'react';
import { shape, string, bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { TopbarContainer } from '..';
import {
  Page,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  FieldSelect,
} from '../../components';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import css from './FAQForGuest.css';
import { Form, FormSpy } from 'react-final-form';
import { parse } from 'util/urlHelpers';

const data = {
  howJoinTimeForYourself: {
    topic: 'How join Time for Yourself',
    details:
      'Sign up, provide us with the information you would like to see in your profile\nBrowse for a specific teacher: enter the name of the teacher you like, you can also find a teacher based on location or\nFind which classes are happening today',
  },
  Bookings: {
    topic: 'Bookings',
    details:
      'Choose the class you like and request to book now. You will need to provide us with your Credit card information, and after the secure payment you are in',
  },
  multipleBookings: {
    topic: 'Multiple bookings',
    details:
      'You can book multiple classes in one go ( for example all classes you want to attend to in one week). All the classes booked are in your Classes.',
  },
  howToJoinAClass: {
    topic: 'How to join a class',
    details: `\nGo into your Classes, find the class and click join now. Please try to join at least 5 min prior to the start of the class.\nGet your mat, your props, sit down and wait till the teacher opens the class.\nEnjoy!`,
  },
  typeOfClassesYouCanBook: {
    topic: 'Type of Classes you can book',
    details:
      'Group classes, online or hybrid( Online/studio)\nOne to one classes, online or in person too',
  },
  classRules: {
    topic: 'Class rules',
    details:
      'Please follow the rules your teacher has set up\nYou can turn on the camera or not, it’s up to you. If you do turn it on, the teacher may be able to give you virtual assists, and you may get more of your practice, but this is not mandatory\nPlease note that you are practicing yoga at your own risk. If you have injuries, or your doctor does not recommend you to practice yoga please don’t.\nMake your practice your own,  and remember to adjust as needed. If you have questions, the teacher will be happy to clarify things for you before or after the class. You can send him/her a message through your Classes.',
  },
  pricesOfTheClasses: {
    topic: 'Prices of the classes',
    details:
      'Each teacher has set up the prices for their classes.\nIf multiple options are given, please choose what you think it’s fair to pay for the teacher and the class.',
  },
  Payments: {
    topic: 'Payments',
    details:
      'You can pay as you go: via secure payment powered by STRIPE with your credit card and in the currency the teacher chooses\nYou can also sign up for a unlimited monthly membership- which gives you access to all classes during a month. *Please note that there might be classes with a top-up ( a small extra fee), and also classes that will only be accessible paying full price- this will depend on the teacher.',
  },
  Equipment: {
    topic: 'Equipment',
    details:
      'You are going to need a computer or ipad, although you can also join by your phone browser\nA mat and props would be helpful; blocks and a strap come in handy\nFor the best experience you will always need a stable internet connection',
  },
  geographicReach: {
    topic: 'Geographic reach',
    details:
      'We are opening almost exclusively in Switzerland, but we also have teachers in some nearby countries in the Eurozone.',
  },
  Events: {
    topic: 'Events',
    details:
      'You can book and attend to Events in our website.\nEvent rules: please follow the organizer’s instructions\nTypes of Events: you book digital only events or hybrid events',
  },
  unlimitedMonthlyMembership: {
    topic: 'Unlimited monthly Membership',
    details:
      'It gives you access to all classes in Time for Yourself\nIt gives you access to all events- some of them may have a top-up ( small extra fee)\nIt renews automatically via secure payment powered by STRIPE, so you can forget about having to renew\nIt can be cancelled at any time- the last bill will not be refunded pro-rata.',
  },
  Cancellations: {
    topic: 'Cancellations',
    details:
      'We hope you don’t need to cancel your attendance to any classes or events, but of course there are things that cannot be predicted! if you have to cancel a class, please do so at the earliest.\nHow to cancel a class:\n\tSign in into your account, and go to your Classes\n\tSelect the class you need to cancel and select cancel\n\tYou will receive an email with the cancellation.\nCancellation rules for a FULL REFUND:\n\tfor instant booking: 15 min prior to the start of the class\n\tfor private classes: at least 24 hours before the booking time\n\tif you cancel your booking outside of this time windows, you will be charged for the full price.\n\tFor Events: at least 24 hours before the day of the event for full refund.',
  },
};

const TermsOfServicePageComponent = props => {
  const { scrollingDisabled, intl, location } = props;
  const { isDirectFromCheckoutPage } = parse(location.search);

  const tabs = [
    {
      text: intl.formatMessage({ id: 'FAQPage.faqForGuest' }),
      selected: true,
      linkProps: {
        name: 'FAQForGuest',
      },
    },
    {
      text: intl.formatMessage({ id: 'FAQPage.faqForHost' }),
      selected: false,
      linkProps: {
        name: 'FAQForHost',
      },
    },
  ];
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'FAQPage.schemaTitle' }, { siteTitle });
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: schemaTitle,
  };

  const topics = Object.entries(data).map(en => ({
    title: en[1].topic,
    key: en[0],
  }));

  const initialValue = isDirectFromCheckoutPage ? data.Cancellations.topic : '';

  return (
    <Page title={schemaTitle} scrollingDisabled={scrollingDisabled} schema={schema}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQForGuest" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.heading}>
              <FormattedMessage id="FAQForGuest.heading" />
            </h1>
            <Form
              onSubmit={() => {}}
              render={() => {
                return (
                  <div>
                    <FieldSelect initialValue={initialValue} name="topic">
                      <option disabled value="">
                        Select a topic
                      </option>
                      {topics.map(tp => (
                        <option key={tp.key} value={tp.key}>
                          {tp.title}
                        </option>
                      ))}
                    </FieldSelect>

                    <FormSpy
                      subscription={{ values: true }}
                      render={({ values }) => {
                        const { topic } = values;
                        const selectedTopic = data[topic];
                        if (selectedTopic) {
                          return (
                            <div>
                              <p style={{ lineBreak: 'normal', whiteSpace: 'pre-wrap' }}>
                                {selectedTopic.details}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

TermsOfServicePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  location: shape({
    search: string,
  }),
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const TermsOfServicePage = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(TermsOfServicePageComponent);

export default TermsOfServicePage;
