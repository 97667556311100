import PropTypes from 'prop-types';
import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const { showDetailCardHeadings, listingTitle, location, geolocation, showAddress } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

DetailCardHeadingsMaybe.propTypes = {
  geolocation: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  listingTitle: PropTypes.string,
  location: PropTypes.object,
  showAddress: PropTypes.bool,
  showDetailCardHeadings: PropTypes.bool,
};

export default DetailCardHeadingsMaybe;
