import { denormalisedResponseEntities } from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const LOAD_TRANSACTION = 'app/ClassJitsyPage/LOAD_TRANSACTION';

// ================ Reducer ================ //

const initialState = {
  transaction: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TRANSACTION:
      return {
        ...state,
        transaction: payload.data,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const loadTransaction = result => ({
  type: LOAD_TRANSACTION,
  payload: result.data,
});

export const loadData = params => async (dispatch, getState, sdk) => {
  const transactions = await sdk.transactions
    .query({
      include: ['listing', 'provider', 'customer', 'booking'],
    })
    .then(res => {
      const transaction = denormalisedResponseEntities(res);
      return transaction;
    });

  const currentTransactions = transactions.filter(
    transaction => transaction.attributes.protectedData.jitsyMeetLink === params.id
  );

  return sdk.transactions
    .show({
      id: currentTransactions[0].id,
      include: ['listing', 'provider', 'customer', 'booking'],
    })
    .then(response => {
      const transaction = denormalisedResponseEntities(response)[0];
      dispatch(addMarketplaceEntities(response));
      dispatch(loadTransaction(response));
      return transaction;
    });
};
